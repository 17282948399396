import { PRODUCTS } from 'src/const';

export const organization = {
  formName: 'organization',
  title: '${this.params.operation} Organization',
  backUrl: '/admin/organizations',
  endpoint: '/organizations/${this.params.id}',
  createEndpoint: '/organizations',
  usesSnakeCase: true,
  fields: [
    {
      name: 'intro',
      interface: 'label',
      type: 'string',
      label:
        'Fields marked with asterick (*) are mandatory.<br>Please contact username@cherre.com to obtain a valid API key.',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'image_url',
      interface: 'image',
      type: 'string',
      label: 'Lightning Connection Logo',
      hint: '',
      required: false,
      validation: null,
      shape: 'round',
    },
    {
      name: 'website_image_url',
      interface: 'image',
      type: 'string',
      label: 'Cherre Website Logo',
      hint: '',
      required: false,
      validation: null,
      shape: 'square',
    },
    {
      name: 'name',
      interface: 'textbox',
      type: 'string',
      label: 'Organization Name',
      hint: 'Acme inc.',
      required: true,
      validation: null,
    },
    {
      name: 'description',
      interface: 'textbox',
      type: 'longString',
      label: 'Description',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'hasura_access_token',
      interface: 'textbox',
      type: 'longString',
      label:
        'Hasura Token (DO NOT USE THIS FIELD, IT IS ONLY FOR ONE SPECIFIC CLIENT)',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'hasura_role',
      interface: 'textbox',
      type: 'string',
      label: `Hasura Role Name for ${PRODUCTS.EXPLORE}/${PRODUCTS.QUERY}`,
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'api_hasura_role',
      interface: 'textbox',
      type: 'string',
      label: 'Hasura Role Name for CoreSchema/API',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'hasura_org_name',
      interface: 'textbox',
      type: 'string',
      label: 'Hasura Organization Name',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'theme_name',
      interface: 'textbox',
      type: 'string',
      label: 'Theme',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'plan_type',
      interface: 'select',
      type: 'string',
      label: 'Plan Type',
      options: [
        { value: 'trial', name: 'Trial' },
        { value: 'standard', name: 'Standard' },
      ],
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'plan_expires_at',
      interface: 'date',
      label: 'Plan Expires At',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'salesforce_id',
      interface: 'textbox',
      type: 'string',
      label: 'Salesforce ID',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'slug',
      interface: 'textbox',
      type: 'string',
      label: 'Slug',
      hint: "Must begin with a letter (a-z, but also letters with diacritical marks and non-Latin letters) or an underscore (_). Subsequent characters in an identifier or key word can be letters, underscores, digits (0-9), or dollar signs ($). It can not start with pg_ or be equal to 'sys'.",
      required: false,
      validation: null,
    },
    {
      name: 'dom_owner',
      interface: 'textbox',
      type: 'string',
      label: 'DOM Owner',
      hint: '',
      required: false,
      validation: null,
    },
  ],
};
