import { FormGroup, FormLabel, styled } from '@cherre-frontend/ui';

export const ProductLines = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 45px;
`;

export const FormGroupStyled = styled(FormGroup)`
  gap: 18px;
`;

export const FormLabelStyled = styled(FormLabel)`
  font-size: 18px;
  font-weight: bold;
  max-width: max-content;
  color: ${({ theme }) => theme.palette.text.primary};
`;
