import React from 'react';
import { useParams } from 'react-router';
import { PageContainer } from '@cherre-frontend/ui';
import { getBackUrl } from 'src/products/admin/utils/getBackUrl';
import type { UserFormRouterParams } from './types';
import { UserForm } from './UserForm';
import { UserFormPageErrorFallback } from './components/UserFormPageErrorFallback';
import { UserFormPaperPageTemplate } from './components/UserFormPaperPageTemplate';

export const UserFormPage = () => {
  const params = useParams<UserFormRouterParams>();
  const backUrl = getBackUrl(
    '/customerAdmin/users',
    `/admin/organizations/${params.organization_id}/users`
  );

  return (
    <PageContainer
      id='user-form-admin'
      errorFallback={(props) => (
        <UserFormPageErrorFallback {...props} backUrl={backUrl} />
      )}
    >
      <UserFormPaperPageTemplate backUrl={backUrl}>
        <UserForm backUrl={backUrl} />
      </UserFormPaperPageTemplate>
    </PageContainer>
  );
};
