import { isAddUserButtonDisabled } from 'src/products/admin/redux/tables/schemas/isAddButtonDisabled';

export const users = {
  id: 'users',
  nameKey: 'email',
  entityName: 'user',
  backUrl: '/admin/organizations',
  addItemUrl: '/admin/organizations/${this.params.id}/users/new',
  endpoint: '/organizations/${this.params.id}/users',
  emulateEndpoint:
    '/api/v1/organizations/${this.params.id}/users/${this.row.id}/emulate',
  isAddButtonDisabled: async (params) => isAddUserButtonDisabled(true)(params),
  actions: [
    'edit',
    'core-bi-dashboards',
    'core-bi-workspaces',
    'permissions',
    'productAccess',
    'disable',
    'app-configuration',
    'emulate',
    'dsp-reassign-user',
    'changeUserOrg',
    're-enable',
    'welcome-email',
  ],
  filters: {
    disabled: {
      label: 'Active',
      mask: 'reverseBoolean',
      value: '',
      options: [false, true],
    },
  },
  columns: [
    {
      field_name: 'firstName',
      column_label: 'First Name',
      mask: '',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'lastName',
      column_label: 'Last Name',
      mask: '',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'email',
      column_label: 'Email Address',
      mask: '',
      sortable: true,
      col_type: 'normal',
      additional_props: {
        url_template_string:
          '/admin/organizations/${this.params.id}/users/${this.row.id}',
      },
    },
    {
      field_name: 'role_description',
      column_label: 'Role',
      mask: '',
      sortable: false,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'disabled',
      column_label: 'Active',
      mask: 'reverseBoolean',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'lastModified',
      column_label: 'Last Updated',
      mask: 'timeago',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'lastSeen',
      column_label: 'Last Seen',
      mask: 'timeago',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
  ],
};
