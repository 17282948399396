import React from 'react';
import { styled, IconButton, AddCircleIcon } from '@cherre-frontend/ui';
import { useDestinations } from './hooks/useDestinations';
import DestinationCard from 'src/components/Connectors/DestinationCard';
import { useUIState } from './useUIState';

const Container = styled('div')`
  display: flex;
  grid-template-columns: 1fr;
  gap: 4px;
  flex-direction: row;
`;

const AddButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

/**
 * `DestinationList` is a React functional component that displays a list of destination cards.
 * It uses the `useDestinations` hook to fetch the list of destinations and the `useUIState` hook to manage UI state.
 *
 * Each destination card displays an image based on the destination type and has an `onClick` handler
 * that sets the UI state to add a destination of the clicked type.
 *
 * There is also an `AddButton` at the end of the list that allows users to add a new destination.
 *
 * @returns {JSX.Element} The rendered list of destination cards and an add button.
 */
const DestinationList: React.FC = () => {
  const { destinations } = useDestinations();
  const [, setUIState] = useUIState();
  return (
    <Container>
      {destinations.map((destination) => (
        <DestinationCard
          image={`/assets/connector-icons/regular-logos/${destination.provider}.svg`}
          onClick={() =>
            setUIState({ step: 'add-destination', type: destination.provider })
          }
        />
      ))}
      <AddButton
        data-testid='add-destination'
        onClick={() => setUIState({ step: 'add-destination' })}
      >
        <AddCircleIcon />
      </AddButton>
    </Container>
  );
};

export default DestinationList;
