export const generalConfiguration = {
  formName: 'general-configuration',
  title: null,
  backUrl: null,
  endpoint: '/organizations/${this.params.id}/attributes',
  createEndpoint: null,
  fields: [
    {
      name: 'id',
      interface: 'hidden',
      type: 'integer',
      label: '',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'Title',
      interface: 'label',
      type: 'string',
      label: `<h2>General Organization Settings</h2>`,
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'max_users_count',
      interface: 'textbox',
      type: 'integer',
      label: 'Max Users Count',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'auth0_connection_name',
      interface: 'textbox',
      type: 'integer',
      label: 'Auth0 Connection Name',
      hint: '',
      required: false,
      validation: null,
    },
  ],
};
