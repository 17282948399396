import { PRODUCTS } from 'src/const';
import axios from 'axios';

export const coreBiConfiguration = {
  formName: 'core-bi-configuration',
  title: null,
  backUrl: null,
  endpoint: '/organizations/${this.params.id}/attributes',
  createEndpoint: null,
  fields: [
    {
      name: 'id',
      interface: 'hidden',
      type: 'integer',
      label: '',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'Title',
      interface: 'label',
      type: 'string',
      label: `<h2>${PRODUCTS.BI} Organization Settings</h2>`,
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'folder_id',
      interface: 'textbox',
      type: 'string',
      label: 'Folder ID',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'organization_looker_email',
      interface: 'select',
      type: 'string',
      label: 'Organization Looker Email',
      options: async () => {
        const url = `/api/v1/organizations/looker-users`;
        try {
          const { data: users } = await axios.get(url);

          return users
            .map((user) => ({
              name: user.email,
              value: user.email,
            }))
            .filter((user) => Boolean(user?.name))
            .sort((a, b) => (a.name > b.name ? 1 : -1));
        } catch (e) {
          return [
            {
              name: 'Could not load looker users',
              value: null,
              disabled: true,
            },
          ];
        }
      },
    },
    {
      name: 'looker_theme',
      interface: 'textbox',
      type: 'string',
      label: 'Looker Theme',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'looker_creating_enabled',
      interface: 'checkbox',
      type: 'boolean',
      label: 'Dashboard Creating Enabled',
      hint: '',
      required: false,
      validation: null,
    },
  ],
};
