export const namespace = 'admin-organizations';
const ns = (name) => `${namespace}/${name}`;

export const GET_ITEM_REQUEST = ns('GET_ITEM_REQUEST');
export const GET_ITEM_SUCCESS = ns('GET_ITEM_SUCCESS');
export const GET_ITEM_FAILED = ns('GET_ITEM_FAILED');

export const CREATE_ITEM_REQUEST = ns('CREATE_ITEM_REQUEST');
export const CREATE_ITEM_SUCCESS = ns('CREATE_ITEM_SUCCESS');
export const CREATE_ITEM_FAILED = ns('CREATE_ITEM_FAILED');

export const UPDATE_ITEM_REQUEST = ns('UPDATE_ITEM_REQUEST');
export const UPDATE_ITEM_SUCCESS = ns('UPDATE_ITEM_SUCCESS');
export const UPDATE_ITEM_FAILED = ns('UPDATE_ITEM_FAILED');

export const RESET_ITEM = ns('RESET_ITEM');

export const FIELD_CHANGED = ns('FIELD_CHANGED');
