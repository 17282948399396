import React from 'react';
import { useHistory, useParams } from 'react-router';
import { PaperPage } from 'src/components/PaperPage';
import type { UserFormRouterParams } from 'src/products/admin/pages/User/Form/types';

interface UserFormPaperPageTemplateProps {
  backUrl: string;
  children: React.ReactNode;
}

export const UserFormPaperPageTemplate = ({
  backUrl,
  children,
}: UserFormPaperPageTemplateProps) => {
  const history = useHistory();
  const params = useParams<UserFormRouterParams>();
  const isEditing = Boolean(params.id);
  const pageTitle = `${isEditing ? 'Edit' : 'Create'} User`;

  const handleBackButtonClick = () => {
    history.push(backUrl);
  };

  return (
    <PaperPage pageTitle={pageTitle} backButtonClick={handleBackButtonClick}>
      {children}
    </PaperPage>
  );
};
