import { customer_api_keys } from './customer_api_keys';
import { customer_users } from './customer_users';
import { datasets } from './datasets';
import { datatables } from './datatables';
import { groups } from './groups';
import { migrations } from './migrations';
import { organizations } from './organizations';
import { signups } from './signups';
import { users } from './users';

export default {
  customer_api_keys,
  customer_users,
  datasets,
  datatables,
  groups,
  migrations,
  organizations,
  signups,
  users,
};
