import React from 'react';
import { Typography, styled } from '@cherre-frontend/ui';

const InputLabelStyled = styled(Typography)``;

const InputDescriptionStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[700]};
`;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const InputLabel = ({
  label,
  description,
}: {
  label: string;
  description: string;
}) => (
  <Wrapper>
    <InputLabelStyled className='suspend'>{label}</InputLabelStyled>
    <InputDescriptionStyled className='suspend'>
      {description}
    </InputDescriptionStyled>
  </Wrapper>
);
