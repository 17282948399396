/**
 * @generated SignedSource<<a4c79d376dc47d79ffe0eb23c224a969>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type VariableInput = {
  name: string;
  value: string;
};
export type useDestinationsEditDestinationMutation$variables = {
  displayName?: string | null;
  provider: string;
  providerConfig?: ReadonlyArray<VariableInput | null> | null;
};
export type useDestinationsEditDestinationMutation$data = {
  readonly updateDestination: {
    readonly config: string | null;
    readonly displayName: string | null;
    readonly name: string;
    readonly provider: string;
  };
};
export type useDestinationsEditDestinationMutation = {
  response: useDestinationsEditDestinationMutation$data;
  variables: useDestinationsEditDestinationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "displayName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "provider"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "providerConfig"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "displayName",
        "variableName": "displayName"
      },
      {
        "kind": "Variable",
        "name": "provider",
        "variableName": "provider"
      },
      {
        "kind": "Variable",
        "name": "providerConfig",
        "variableName": "providerConfig"
      }
    ],
    "concreteType": "Destination",
    "kind": "LinkedField",
    "name": "updateDestination",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "provider",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "config",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useDestinationsEditDestinationMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useDestinationsEditDestinationMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "fe99ae18fce7c0ba1d59c79717afdecf",
    "id": null,
    "metadata": {},
    "name": "useDestinationsEditDestinationMutation",
    "operationKind": "mutation",
    "text": "mutation useDestinationsEditDestinationMutation(\n  $provider: String!\n  $providerConfig: [VariableInput]\n  $displayName: String\n) {\n  updateDestination(provider: $provider, providerConfig: $providerConfig, displayName: $displayName) {\n    name\n    provider\n    displayName\n    config\n  }\n}\n"
  }
};
})();

(node as any).hash = "32571900af400bd2ae92be0419ce01f7";

export default node;
