import React from 'react';
import type { ErrorBoundaryComponent } from '@cherre-frontend/data-fetching';
import { Typography } from '@cherre-frontend/ui';
import { UserFormPaperPageTemplate } from './UserFormPaperPageTemplate';

const getUserFetchErrorRegExp = new RegExp(
  /^fetch failed for URL \/api\/v1\/organizations\/.+\/users/
);

const getErrorMessageToDisplay = (errorMessage: string) => {
  if (getUserFetchErrorRegExp.test(errorMessage)) {
    return 'User not found';
  }

  if (errorMessage?.includes('Organization not found')) {
    return 'Organization not found';
  }

  return 'An error occurred';
};

type UserFormPageErrorFallbackProps =
  React.ComponentProps<ErrorBoundaryComponent> & { backUrl: string };

export const UserFormPageErrorFallback = ({
  backUrl,
  error,
}: UserFormPageErrorFallbackProps) => {
  const errorAsObject: { message?: string } =
    error && typeof error === 'object' ? error : {};
  const errorMessage = getErrorMessageToDisplay(errorAsObject.message || '');

  return (
    <UserFormPaperPageTemplate backUrl={backUrl}>
      <Typography marginY='50px'>{errorMessage}</Typography>
    </UserFormPaperPageTemplate>
  );
};
