import constants from 'src/const';
import { User } from 'src/products/admin/types/user';
import { Organization } from 'src/products/admin/types/organization';
import { FetchStatus } from 'src/products/admin/types/fetch-status';
import * as types from './types';

interface OrganizationReducerState {
  organization: Organization | null;
  users: User[];
  selectedUser: object;
  usersFetchStatus: Record<string, FetchStatus>;
  getUserFetchStatus: Record<string, FetchStatus>;
  organizationFetchStatus: Record<string, FetchStatus>;
  enableUserFetchStatus: Record<string, FetchStatus>;
  saveUserFetchStatus: Record<string, FetchStatus>;
  isAddUserFormVisible: boolean;
}

const { LOADING, LOADED, FAILED } = constants;

const initialState: OrganizationReducerState = {
  organization: null,
  users: [],
  selectedUser: {},
  usersFetchStatus: {},
  getUserFetchStatus: {},
  organizationFetchStatus: {},
  enableUserFetchStatus: {},
  saveUserFetchStatus: {},
  isAddUserFormVisible: false,
};

export default function organization(
  state = initialState,
  action
): OrganizationReducerState {
  switch (action.type) {
    case types.RESET: {
      return initialState;
    }

    case types.GET_ORGANIZATION_REQUEST: {
      return {
        ...state,
        organizationFetchStatus: {
          value: LOADING,
        },
      };
    }

    case types.GET_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        organization: action.data,
        organizationFetchStatus: {
          value: LOADED,
        },
      };
    }

    case types.GET_ORGANIZATION_FAILED: {
      return {
        ...state,
        organization: null,
        organizationFetchStatus: {
          value: FAILED,
        },
      };
    }

    case types.SHOW_ADD_USER_FORM: {
      return {
        ...state,
        isAddUserFormVisible: true,
      };
    }

    case types.HIDE_ADD_USER_FORM: {
      return {
        ...state,
        isAddUserFormVisible: false,
      };
    }

    case types.REENABLE_USER_REQUEST: {
      return {
        ...state,
        enableUserFetchStatus: {
          value: LOADING,
        },
      };
    }

    case types.REENABLE_USER_SUCCESS: {
      return {
        ...state,
        enableUserFetchStatus: {
          value: LOADED,
        },
      };
    }

    case types.REENABLE_USER_FAILED: {
      return {
        ...state,
        enableUserFetchStatus: {
          value: FAILED,
        },
      };
    }

    case types.SAVE_USER_REQUEST: {
      return {
        ...state,
        saveUserFetchStatus: {
          value: LOADING,
        },
      };
    }

    case types.SAVE_USER_SUCCESS: {
      return {
        ...state,
        saveUserFetchStatus: {
          value: LOADED,
        },
      };
    }

    case types.SAVE_USER_FAILED: {
      return {
        ...state,
        saveUserFetchStatus: {
          value: FAILED,
        },
      };
    }

    case types.GET_USER_REQUEST: {
      return {
        ...state,
        getUserFetchStatus: {
          value: LOADING,
        },
      };
    }

    case types.GET_USER_SUCCESS: {
      return {
        ...state,
        selectedUser: action.data,
        getUserFetchStatus: {
          value: LOADED,
        },
      };
    }

    case types.GET_USER_FAILED: {
      return {
        ...state,
        getUserFetchStatus: {
          value: FAILED,
        },
      };
    }

    case types.GET_USERS_REQUEST: {
      return {
        ...state,
        usersFetchStatus: {
          value: LOADING,
        },
      };
    }

    case types.GET_USERS_SUCCESS: {
      return {
        ...state,
        users: action.data.filter((u) => !u.disabled),
        usersFetchStatus: {
          value: LOADED,
        },
      };
    }

    default: {
      return state;
    }
  }
}
