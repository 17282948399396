import React from 'react';

import { useDispatch } from 'react-redux';
import { getLookerDashboards } from 'src/products/admin/redux/dashboards/actions';
import { Dashboard } from 'src/products/data-connections/types';

const getField = (dashboard: Dashboard) => ({
  style: { width: '90%', marginTop: 0, marginBottom: 0 },
  name: `id-${dashboard.slug}`,
  interface: 'checkbox',
  type: 'boolean',
  label: dashboard.name,
});

const useFields = (organization_id: number, action = getLookerDashboards) => {
  const [dashboards, setDashboards] = React.useState<Dashboard[]>([]);
  const [isLoading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const dashboards = (await dispatch(
          action(organization_id)
        )) as any as Dashboard[];
        setDashboards(dashboards);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    })();
  }, []);

  return { fields: dashboards.map(getField), isLoading };
};

export default useFields;
