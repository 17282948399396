import allPermissions from 'src/const/permissions';

const filterPermission = (availablePermission) => {
  return allPermissions.find(
    (permission) =>
      permission.permission === availablePermission.id ||
      permission.children?.some(
        (child) => child.permission === availablePermission.id
      )
  );
};

const permissionsToDictionary = (permissions) => {
  const dictionary = {};

  permissions.forEach((permission) => {
    const productLine = permission.productLine;

    dictionary[productLine]
      ? dictionary[productLine].push(permission)
      : (dictionary[productLine] = [permission]);
  });

  return dictionary;
};

const augmentPermission = (availablePermission) => {
  const match = filterPermission(availablePermission);

  return Object.assign({}, availablePermission, match);
};

const createProductLineField = (productLine) => ({
  name: productLine,
  interface: 'label',
  type: 'string',
  label: `<div style="font-size:18px; color: black; margin-top:40px; font-weight:500;" >${productLine}</div>`,
  hint: '',
  required: false,
  validation: null,
});

const createProductField = (product) => ({
  name: product.id,
  interface: 'checkbox',
  type: 'boolean',
  label: null,
  hint: product.description,
  style: { width: '90%', marginTop: 0, marginBottom: 0 },
  templateString:
    `</br><span style="color: black" >${product.label || product.name}</span>` +
    (product.description
      ? `</br><span style="color: #757575" >${product.description}</span>`
      : ''),
  required: false,
  validation: null,
});

const buildFields = (permissionsByProductLine) => {
  let fields = [];

  Object.keys(permissionsByProductLine).forEach((key) => {
    const productLine = permissionsByProductLine[key];
    const productField = createProductLineField(key);
    const products = productLine.map(createProductField);

    fields = fields.concat([productField], products);
  });

  return fields;
};

const getPermissionFields = (availablePermissions = []) => {
  // for each available permission get the productLine & descriptions
  const augumentedPermissions = availablePermissions
    .filter(filterPermission)
    .map(augmentPermission);

  //iterate and add to dictionary of product lines.
  const permissionsByProductLine = permissionsToDictionary(
    augumentedPermissions
  );

  // buildFields from the dictionary
  const fieldsList = buildFields(permissionsByProductLine);

  return fieldsList;
};

export {
  getPermissionFields,
  permissionsToDictionary,
  augmentPermission,
  buildFields,
  createProductField,
};
