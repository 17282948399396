import React from 'react';
import { MuiRadioProps } from '@cherre-frontend/ui';
import { useIsSuspended } from '@cherre-frontend/data-fetching';
import { RadioStyled, FormControlLabelStyled } from './styles';
import { InputLabel } from '../InputLabel';

type RadioProps = {
  label: string;
  description: string;
  value: MuiRadioProps['value'];
  disabled?: MuiRadioProps['disabled'];
  className?: MuiRadioProps['className'];
};

export const Radio = ({
  description,
  label,
  value,
  disabled,
  className,
}: RadioProps) => {
  const suspended = useIsSuspended();

  return (
    <FormControlLabelStyled
      value={value}
      disabled={disabled}
      className={className}
      control={suspended ? <></> : <RadioStyled />}
      label={<InputLabel label={label} description={description} />}
    />
  );
};
