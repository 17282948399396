import React from 'react';
import { Field } from 'react-final-form';
import { Checkbox, FormControl, FormControlLabel } from '@cherre-frontend/ui';

interface UserFormCheckboxFieldProps {
  name: string;
  label: string;
  validator?: (value: string) => string | undefined;
}

export const UserFormCheckboxField = (props: UserFormCheckboxFieldProps) => {
  const { name, label, validator } = props;

  return (
    <Field name={name} type='checkbox' validate={validator}>
      {({ input }) => (
        <FormControl fullWidth variant='standard' className='suspend'>
          <FormControlLabel
            label={label}
            control={<Checkbox color='primary' {...input} />}
          />
        </FormControl>
      )}
    </Field>
  );
};
