import * as types from './types';
import fillTemplate from 'src/utils/fillTemplate';

export function resetItem({ formName }) {
  return async (dispatch) => {
    return dispatch({
      type: types.RESET_ITEM,
      formName: formName,
    });
  };
}

export function getItem({ formName, endpoint }, params) {
  return async (dispatch) => {
    return dispatch({
      url: fillTemplate(endpoint, { params }),
      types: [
        types.GET_ITEM_REQUEST,
        types.GET_ITEM_SUCCESS,
        types.GET_ITEM_FAILED,
      ],
      dataToDispatch: { formName },
    });
  };
}

export function createItem({ formName, createEndpoint }, params, fieldValues) {
  return async (dispatch) => {
    return dispatch({
      url: fillTemplate(createEndpoint, { params }),
      method: 'post',
      send: fieldValues,
      types: [
        types.CREATE_ITEM_REQUEST,
        types.CREATE_ITEM_SUCCESS,
        types.CREATE_ITEM_FAILED,
      ],
      dataToDispatch: { formName, fieldValues },
    });
  };
}

export function updateItem({ formName, endpoint }, params, fieldValues) {
  return async (dispatch) => {
    return dispatch({
      url: fillTemplate(endpoint, { params }),
      method: 'put',
      send: fieldValues,
      types: [
        types.UPDATE_ITEM_REQUEST,
        types.UPDATE_ITEM_SUCCESS,
        types.UPDATE_ITEM_FAILED,
      ],
      dataToDispatch: { formName, fieldValues },
    }).catch((error) => {
      /* The API will return a 400 error with a message if the Connector assignment fails. 
      We need to intercept that and force an update_item_failed because the current setup of the reducers
      will never run the getFormReducer with the organizationPermissions schema. Also, by throwing an
      additional error after the dispatch, it will be caught in the getFormComponent to prevent the afterSubmit from running
      and showing a success message with the failed message.
      */

      dispatch({
        type: types.UPDATE_ITEM_FAILED,
        formName: 'organization',
        error: {
          message: error.response.data.message,
        },
      });
      throw error;
    });
  };
}

const getActions = (schema) => {
  return {
    getItem: getItem.bind(null, schema),
    createItem: createItem.bind(null, schema),
    updateItem: updateItem.bind(null, schema),
    resetItem: resetItem.bind(null, schema),
  };
};

export default getActions;
