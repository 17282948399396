import * as types from './types';
import schemas from './schemas';
import fillTemplate from 'src/utils/fillTemplate';

export function deleteRow(schemaId, params, itemId) {
  return async (dispatch) => {
    return dispatch({
      url: `${fillTemplate(schemas[schemaId].endpoint, { params })}/${itemId}`,
      method: 'delete',
      types: [
        types.DELETE_ITEM_REQUEST,
        types.DELETE_ITEM_SUCCESS,
        types.DELETE_ITEM_FAILED,
      ],
      dataToDispatch: { schemaId },
    });
  };
}

export function getRows(schemaId, params, countOnly = undefined) {
  return async (dispatch, getState) => {
    return dispatch({
      url: fillTemplate(schemas[schemaId].endpoint, { params }),
      types: countOnly
        ? [
            types.GET_ITEMS_REQUEST_COUNT,
            types.GET_ITEMS_SUCCESS_COUNT,
            types.GET_ITEMS_FAILED_COUNT,
          ]
        : [
            types.GET_ITEMS_REQUEST,
            types.GET_ITEMS_SUCCESS,
            types.GET_ITEMS_FAILED,
          ],
      dataToDispatch: { schemaId },
      query: {
        orderField: getState().adminPanel[schemaId].orderBy,
        orderDirection: getState().adminPanel[schemaId].order,
        search: getState().adminPanel[schemaId].searchValue,
        filters: JSON.stringify(
          Object.fromEntries(
            Object.entries(getState().adminPanel[schemaId].filters).map(
              ([k, v]) => [k, v.value]
            )
          )
        ),
        page: getState().adminPanel[schemaId].page,
        rows: getState().adminPanel[schemaId].rowsPerPage,
        countOnly,
      },
    });
  };
}

export function sort(schemaId, params, orderField) {
  return async (dispatch, getState) => {
    const orderBy = orderField;
    let order = 'desc';

    if (
      getState().adminPanel[schemaId].orderBy === orderField &&
      getState().adminPanel[schemaId].order === 'desc'
    ) {
      order = 'asc';
    }

    dispatch({
      type: types.CHANGE_ORDER,
      order,
      orderBy,
      schemaId,
    }).then(() => {
      dispatch(getRows(schemaId, params));
    });
  };
}

export function changePage(schemaId, local, params, event, page) {
  return async (dispatch) => {
    dispatch({
      type: types.CHANGE_PAGE,
      page,
      schemaId,
    }).then(() => {
      if (!local) {
        dispatch(getRows(schemaId, params));
      }
    });
  };
}

export function changeRowsPerPage(schemaId, local, params, event) {
  return async (dispatch) => {
    dispatch({
      type: types.CHANGE_ROWS_PER_PAGE,
      rowsPerPage: event.target.value,
      schemaId,
    }).then(() => {
      if (!local) {
        dispatch(getRows(schemaId, params));
      }
    });
  };
}

export function search(schemaId, local = true, params, searchValue = '') {
  return async (dispatch) => {
    dispatch({
      type: types.CHANGE_SEARCH_VALUE,
      searchValue,
      schemaId,
    }).then(() => {
      if (!local) {
        dispatch(getRows(schemaId, params));
        dispatch(getRows(schemaId, params, true));
      }
    });
  };
}

export function setFilterValue(schemaId, local, params, field, value) {
  return async (dispatch) => {
    dispatch({
      type: types.SET_FILTER_VALUE,
      field,
      value,
      schemaId,
    }).then(() => {
      if (!local) {
        dispatch(getRows(schemaId, params));
        dispatch(getRows(schemaId, params, true));
      }
    });
  };
}

const getTableActions = (schemaId, local = true) => {
  return {
    deleteRow: deleteRow.bind(null, schemaId),
    getRows: getRows.bind(null, schemaId),
    sort: sort.bind(null, schemaId),
    changePage: changePage.bind(null, schemaId, local),
    changeRowsPerPage: changeRowsPerPage.bind(null, schemaId, local),
    search: search.bind(null, schemaId, local),
    setFilterValue: setFilterValue.bind(null, schemaId, local),
  };
};

export default getTableActions;
