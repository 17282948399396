import React from 'react';
import { getSelector, usePost } from '@cherre-frontend/data-fetching';
import { WorkspaceAccessAdmin } from 'src/products/core-bi-v2/WorkspaceAccessAdmin';
import { RouteComponentProps, withRouter } from 'react-router-dom';

type Props = RouteComponentProps<{ user_id: string }>;

const workspaceAccessRequest = function <T>(userId: string) {
  return getSelector<T>({ url: `/api/v1/powerbi/workspace-access/${userId}` });
};

export default withRouter(({ match, history }: Props) => {
  const userId = match?.params?.user_id;
  const backClick = () => history.replace('/customerAdmin/users');

  const update = usePost(`/api/v1/powerbi/workspace-access`, {
    onCompleted: (result, context) => {
      context.recoil.reset(workspaceAccessRequest(userId));
      context.showSnackbar({
        type: 'success',
        message: 'User workspace access updated',
      });
    },
  });

  return (
    <WorkspaceAccessAdmin
      title='User Workspaces'
      backClick={backClick}
      workspaceAccess={workspaceAccessRequest(userId)}
      update={(formState) => {
        update({
          userId: parseInt(userId),
          workspaces: Object.keys(formState).map((id) => ({
            id,
            hasAccess: formState[id],
          })),
        });
      }}
    />
  );
});
