import schemas from './schemas';
import * as types from './types';

const getInitialState = () => ({
  fetchStatus: {},
  updateStatus: {},
  data: {},
  permissionListData: [],
});

const convertPermissionListToFormData = (permissionsList) => {
  const formData = {};
  permissionsList.forEach((permission) => {
    formData[permission.id] = permission.enabled;
  });
  return formData;
};

export const getPermissionsReducer = (schemaId) => {
  const initialState = getInitialState();

  function reducer(state = initialState, action) {
    if (!action.schemaId) {
      return state;
    }

    if (action.schemaId !== schemaId) {
      return state;
    }

    switch (action.type) {
      case types.GET_PERMISSIONS_REQUEST: {
        return {
          ...state,
          fetchStatus: {
            value: 'LOADING',
          },
        };
      }

      case types.GET_PERMISSIONS_SUCCESS: {
        return {
          ...state,
          data: convertPermissionListToFormData(action.data),
          permissionListData: action.data,
          fetchStatus: {
            value: 'LOADED',
          },
        };
      }

      default:
        return state;
    }
  }

  return reducer;
};

function getPermissionsReducers() {
  return Object.keys(schemas).reduce((memo, schemaId) => {
    memo[schemaId] = getPermissionsReducer(schemaId);
    return memo;
  }, {});
}

export default getPermissionsReducers;
