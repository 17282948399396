import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import getFormComponent from 'src/products/admin/pages/getFormComponent';
import { getPermissions } from 'src/products/admin/redux/permissions/getActions';
import { getPermissionFields } from 'src/products/admin/redux/permissions/createPermissionFields';

import schemas from 'src/products/admin/redux/permissions/schemas';
import { withRouter } from 'react-router';
import { filterCherreAdminPermissions } from 'src/products/admin/utils/CherreAdmin';

class Permissions extends React.Component {
  //const Component = getPermissionsComponent();
  formData = Object.assign({ fields: [] }, schemas.organizationPermissions);
  updatedparams = {
    id: this.props.match.params.user_id,
    ...this.props.match.params,
  };
  componentDidMount() {
    this.props.getPermissions(this.formData.id, this.updatedparams);
  }

  render() {
    const props = this.props;
    if (!props.match.params.organization_id) {
      return null;
    }
    // get available permissions and filter out Cherre*Admin permissions if the organization is not in the allowed orgs
    const availablePermissions = filterCherreAdminPermissions(
      props.availablePermissions,
      props.match.params.organization_id,
      props.isCherreAdmin
    );
    const fields = getPermissionFields(availablePermissions);

    this.formData.fields = fields;

    const FormComponent = getFormComponent(this.formData);

    return (
      <FormComponent
        params={{
          formData: this.formData,
          ...props.match.params,
          id: true, //forces it into edit mode
        }}
      />
    );
  }
}

Permissions.propTypes = {
  params: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    availablePermissions:
      state.adminPanel.organizationPermissions.permissionListData,
    isCherreAdmin: (state.user.profile.value.permissions || []).includes(
      'CherreAdmin'
    ),
  };
}

export default connect(mapStateToProps, {
  getPermissions,
  getPermissionFields,
})(withRouter(Permissions));
