import React, { useState } from 'react';
import { Typography, styled, Tree } from '@cherre-frontend/ui';
import {
  useDestinations,
  FetchKeyContextProvider,
} from './hooks/useDestinations';
import NoDestinations from './NoDestinations';
import ConfigureDestination from './ConfigureDestination';
import { UIState, UIStateProvider } from './useUIState';
import DestinationList from './DestinationList';

const Container = styled('div')`
  display: grid;
  grid-template-rows: auto 4px auto 20px auto;
  grid-template-areas: 'title' '.' 'subtitle' '.' 'content';
`;

const Title = styled(Typography)`
  grid-area: title;
  color: ${({ theme }) => theme.palette.text.primary};
  font-feature-settings: 'liga' off;

  /* Headings/H6 */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Subtitle = styled(Typography)`
  grid-area: subtitle;
  color: ${({ theme }) => theme.palette.grey[900]};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.5px; /* 125% */
  letter-spacing: 0.14px;
`;

/**
 * The `Destinations` component is a React functional component that displays a list of destinations
 * or a message indicating that there are no destinations. It allows users to view, edit, or add new destinations.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * // Usage example:
 * <Destinations />
 *
 * @remarks
 * This component uses the `useDestinations` hook to fetch the list of destinations and manages
 * the UI state based on whether there are destinations available or not.
 *
 * @hook
 * @name useDestinations
 * @returns {Object} An object containing the list of destinations.
 *
 * @state {string} uiState - The current UI state, which can be 'destinations-list', 'no-destinations', or 'add-destination'.
 * @state {function} setUiState - Function to update the UI state.
 *
 * @children
 * - `Container`: The main container for the component.
 * - `Title`: Displays the title "Your Destinations:".
 * - `Subtitle`: Provides instructions for interacting with the destinations.
 * - `Tree.Switch`: Switches between different UI states.
 * - `Tree.Case`: Displays the appropriate UI based on the current state.
 * - `NoDestinations`: Component displayed when there are no destinations, with an onClick handler to change the state.
 */
const Destinations: React.FC = () => {
  const { destinations } = useDestinations();
  const [uiState, setUiState] = useState<UIState>({
    step: 'destinations-list',
  });
  return (
    <UIStateProvider value={[uiState, setUiState]}>
      <Tree.Switch value={uiState.step}>
        <Tree.Case value='destinations-list'>
          <Container>
            <Title>Your Destinations:</Title>
            <Subtitle>
              Click on a destination to view or edit its details. Click on the
              add button to configure a new destination.
            </Subtitle>
            <div style={{ gridArea: 'content' }}>
              <Tree.Switch value={Boolean(destinations.length)}>
                <Tree.Case value={false}>
                  <NoDestinations />
                </Tree.Case>
                <Tree.Case value>
                  <DestinationList />
                </Tree.Case>
              </Tree.Switch>
            </div>
          </Container>
        </Tree.Case>
        <Tree.Case value='add-destination'>
          <ConfigureDestination />
        </Tree.Case>
      </Tree.Switch>
    </UIStateProvider>
  );
};

export default () => {
  return (
    <FetchKeyContextProvider>
      <Destinations />
    </FetchKeyContextProvider>
  );
};
