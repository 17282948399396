import { MuiRadio, FormControlLabel, styled } from '@cherre-frontend/ui';

export const RadioStyled = styled(MuiRadio)`
  padding: 2px 9px;
  align-self: flex-start;
  margin-left: -11px;
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  margin-left: 0;
`;
