import React, { useCallback, useMemo } from 'react';
import { Checkbox } from '../../../../components/Checkbox';
import { Radio } from '../../../../components/Radio';
import { PermissionType } from 'src/const/permissions';
import { RadioGroupStyled } from './styles';

type FormState = {
  [permission: string]: boolean;
};

type PermissionSelectionProps = {
  initialFormState?: FormState;
  formState: FormState;
  setFormState: (state: FormState) => void;
  permission: PermissionType;
};

export const PermissionSelection = ({
  permission,
  initialFormState,
  formState,
  setFormState,
}: PermissionSelectionProps) => {
  const checkboxChecked = useMemo(
    () =>
      permission.children
        ? permission.children.some((c) => !!formState?.[c.permission])
        : !!formState?.[permission.permission],
    [permission.children, formState]
  );

  const radioGroupValue = useMemo(
    () =>
      permission.children?.find((child) => !!formState?.[child.permission])
        ?.permission ?? null,
    [permission.children, formState]
  );

  const changeChildren = useCallback(
    (newValue: (permission: string) => boolean) => {
      const newState = { ...formState };

      const permissionsToChange = permission.children ?? [permission];

      permissionsToChange.forEach(({ permission: permissionToChange }) => {
        newState[permissionToChange] = newValue(permissionToChange);
      });

      setFormState(newState);
    },
    [formState, permission, setFormState]
  );

  const onChangeCheckbox = useCallback(
    (e) => {
      const checked = e.target.checked;

      const permissionToEnable =
        permission.children?.find((p) => !!initialFormState?.[p.permission])
          ?.permission ?? permission.permission;

      changeChildren((p) => checked && p === permissionToEnable);
    },
    [permission.permission, changeChildren, initialFormState]
  );

  const onChangeRadioGroup = useCallback(
    (e) => {
      const radioValue = e.target.value;
      changeChildren((p) => p === radioValue);
    },
    [changeChildren]
  );

  return (
    <div>
      <Checkbox
        onChange={onChangeCheckbox}
        checked={checkboxChecked}
        label={permission.label ?? permission.permission}
        description={permission.description}
      />
      {permission.children?.length && (
        <RadioGroupStyled onChange={onChangeRadioGroup} value={radioGroupValue}>
          {permission.children.map((child) => (
            <Radio
              value={child.permission}
              disabled={!checkboxChecked}
              key={child.permission}
              label={child.label ?? child.permission}
              description={child.description}
            />
          ))}
        </RadioGroupStyled>
      )}
    </div>
  );
};
