import React from 'react';
import { FormControlLabelProps } from '@cherre-frontend/ui';
import { useIsSuspended } from '@cherre-frontend/data-fetching';
import { CheckboxStyled, FormControlLabelStyled } from './styles';
import { InputLabel } from '../InputLabel';

type CheckboxProps = {
  label: string;
  description: string;
  checked: boolean;
  className?: FormControlLabelProps['className'];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Checkbox = ({
  onChange,
  checked,
  description,
  label,
  className,
}: CheckboxProps) => {
  const suspended = useIsSuspended();

  return (
    <FormControlLabelStyled
      className={className}
      control={
        suspended ? (
          <></>
        ) : (
          <CheckboxStyled
            className='suspend'
            onChange={onChange}
            checked={checked}
          />
        )
      }
      label={<InputLabel label={label} description={description} />}
    />
  );
};
