export const customer_api_keys = {
  id: 'customer_api_keys',
  nameKey: 'id',
  entityName: 'API key',
  backUrl: null,
  addItemUrl: '/customerAdmin/api-keys/new',
  endpoint: '/api-keys',
  actions: ['edit-api-key', 'disable-api-key'],
  columns: [
    {
      field_name: 'id',
      column_label: 'ID',
      mask: '',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'nickname',
      column_label: 'Nickname',
      mask: '',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'status',
      column_label: 'Status',
      mask: '',
      sortable: false,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'createdAt',
      column_label: 'Created At',
      mask: 'timeago',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'deactivatedAt',
      column_label: 'Deactivated At',
      mask: 'timeago',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
  ],
};
