import { useSelector } from 'react-redux';
import { getSelector, useCherreValue } from '@cherre-frontend/data-fetching';
import type { Organization } from 'src/products/admin/types/organization';

const DEFAULT_AUTHZ_VERSION_NAME = 'legacy';

const getOrganizationSelector = (organizationId: string) => {
  return getSelector<Organization>({
    url: `/api/v1/organizations/${organizationId}`,
  });
};

export const useOrganizationAuthzVersionName = (
  organizationId: string
): string | undefined => {
  const currentUserOrganizationAuthZVersionName = useSelector(
    (state) => state.user.profile.value.organizationAuthzVersion?.name
  );

  if (currentUserOrganizationAuthZVersionName) {
    return currentUserOrganizationAuthZVersionName;
  }

  const organization = useCherreValue(getOrganizationSelector(organizationId));

  if (!organization) {
    return undefined;
  }

  return organization.authzVersion?.name || DEFAULT_AUTHZ_VERSION_NAME;
};
