import React from 'react';
import { Field } from 'react-final-form';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FormControl, InputLabel, MenuItem, Select } from '@cherre-frontend/ui';

interface UserFormSelectFieldProps {
  name: string;
  label: string;
  options: { label: string; value: string }[];
  validator?: (value: string) => string | undefined;
}

export const UserFormSelectField = (props: UserFormSelectFieldProps) => {
  const { label, name, options, validator } = props;

  return (
    <Field name={name} validate={validator}>
      {({ input, meta: { error, touched } }) => (
        <FormControl
          required
          fullWidth
          variant='standard'
          className='suspend'
          error={touched && error}
        >
          <InputLabel>{label}</InputLabel>
          <Select {...input} label={label}>
            {options.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
          {touched && error && (
            <FormHelperText error={touched && error}>{error}</FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  );
};
