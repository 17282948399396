export const datasetDashboards = {
  formName: 'dashboards',
  title: 'Dataset Dashboards',
  backUrl: '/admin/organizations/${this.params.organization_id}/datasets',
  noId: true,
  endpoint:
    '/organizations/${this.params.organization_id}/datasets/${this.params.id}/dashboards-form-data',
  createEndpoint: '/organizations/${this.params.organization_id}/datasets',
  fields: [
    {
      name: 'Dataset Dashboards',
      interface: 'checkbox',
      type: 'boolean',
      label: 'Dataset Dashboards',
      hint: 'test',
      required: false,
      validation: null,
    },
  ],
};
