import React, { useState } from 'react';
import {
  styled,
  Button,
  ArrowBackIcon,
  Typography,
  Tree,
} from '@cherre-frontend/ui';
import DestinationCard from 'src/components/Connectors/DestinationCard';
import Snowflake from './DestinationConfigurations/Snowflake';
import { useDestinations } from './hooks/useDestinations';
import { useUIState } from './useUIState';

const Container = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  gap: 4px;
  justify-items: flex-start;
`;

const Content = styled('div')`
  display: grid;
  padding: 20px 30px;
  gap: 12px;
  border-radius: 5px;
  background: ${({ theme }) => theme.palette.grey[100]};
  width: 100%;
  margin-bottom: 20px;
`;

const ContentTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-feature-settings: 'liga' off;

  /* Headings/H6 */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Carroussel = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 10px;
`;

const BackButton = styled(Button)``;

/**
 * ConfigureDestination component allows users to configure a destination for their data.
 *
 * This component uses the `useUIState` hook to manage the UI state and the `useDestinations` hook to fetch the available destinations.
 *
 * The component renders a back button to navigate back to the destinations list, a title, and a carousel with destination cards.
 *
 * When a destination card is clicked, it sets the selected destination and displays the corresponding configuration component.
 *
 * @component
 * @example
 * return (
 *   <ConfigureDestination />
 * )
 *
 * @returns {JSX.Element} The rendered ConfigureDestination component.
 */
const ConfigureDestination: React.FC = () => {
  const [UIState, setUIState] = useUIState();
  const initial =
    UIState.step === 'add-destination' ? UIState.type || null : null;
  const [selected, setSelected] = useState<string | null>(initial);
  const { destinations } = useDestinations();
  return (
    <Container>
      <BackButton
        startIcon={<ArrowBackIcon />}
        onClick={() => setUIState({ step: 'destinations-list' })}
      >
        Back to Configure Destinations
      </BackButton>
      <Content>
        <ContentTitle>1. Configure a destination:</ContentTitle>
        <Carroussel>
          <DestinationCard
            image='/assets/connector-icons/regular-logos/snowflake.svg'
            selected={selected === 'snowflake'}
            disabled={destinations.some((d) => d.provider === 'snowflake')}
            onClick={() => setSelected('snowflake')}
          />
        </Carroussel>
      </Content>
      <Tree.Switch value={selected}>
        <Tree.Case value='snowflake'>
          <Snowflake onCancel={() => setSelected(null)} />
        </Tree.Case>
      </Tree.Switch>
    </Container>
  );
};

export default ConfigureDestination;
