import {
  getSelector,
  usePut,
  CallbackRecoilContext,
} from '@cherre-frontend/data-fetching';
import { Permission } from 'src/products/shell/redux/reducers/user';

export type PermissionStatus = {
  id: string;
  name: Permission;
  enabled: boolean;
};

export const productAccessSelector = (organizationId: number, userId: number) =>
  getSelector<PermissionStatus[]>({
    url: `/api/v1/organizations/${organizationId}/users/${userId}/permissions`,
  });

export const useUpdatePermissions = (
  organizationId: number,
  userId: number,
  onCompleted: (context: CallbackRecoilContext) => void
) => {
  return usePut(
    `/api/v1/organizations/${organizationId}/users/${userId}/permissions`,
    {
      onCompleted: (result, context) => {
        context.recoil.reset(productAccessSelector(organizationId, userId));
        onCompleted(context);
      },
    }
  );
};
