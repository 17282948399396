import React, { Suspense, useMemo, useState } from 'react';
import { styled, Typography, Button, MenuItem } from '@cherre-frontend/ui';
import * as C from './styles';
import { useDestinations } from '../hooks/useDestinations';
import { useUIState } from '../useUIState';
import { useRegions } from './hooks/useRegions';
import Skeleton from './Snowflake.skeleton';

export const Container = styled('div')`
  display: grid;
  padding: 20px 30px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 5px;
  background: ${({ theme }) => theme.palette.grey[100]};
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-feature-settings: 'liga' off;

  /* Headings/H6 */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const Text = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};

  /* Body/Normal */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.5px; /* 125% */
  letter-spacing: 0.14px;
`;

export const FieldsContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

type SnowflakeProps = {
  onCancel: () => void;
};

/**
 * Snowflake component for configuring Snowflake destinations.
 *
 * @component
 * @param {SnowflakeProps} props - The properties for the Snowflake component.
 * @param {Function} props.onCancel - Callback function to handle the cancel action.
 *
 * @returns {JSX.Element} The rendered Snowflake configuration component.
 *
 * @example
 * <Snowflake onCancel={handleCancel} />
 *
 * @remarks
 * This component allows users to configure Snowflake destinations by providing
 * the Snowflake Account ID and Region. It uses the `useDestinations` hook to
 * manage destination data and the `useUIState` hook to manage UI state.
 *
 * The component displays information about the Cherre Egress for Snowflake and
 * provides input fields for the Account ID and Region. It also includes actions
 * for canceling the configuration or proceeding to the next step.
 */
const Snowflake: React.FC<SnowflakeProps> = ({ onCancel }) => {
  const { destinations, addOrEditDestination } = useDestinations();
  const regions = useRegions();
  const [, setUIState] = useUIState();
  const preExisting = destinations.find((d) => d.provider === 'snowflake');
  const config = useMemo(
    () => JSON.parse(preExisting?.config || '{}'),
    [preExisting]
  );
  const [accountID, setAccountID] = useState(config?.accountID || '');
  const [region, setRegion] = useState(config?.region || '');
  return (
    <Container>
      <Title>2. Implementation Details</Title>
      <Text>
        Configuring Snowflake involves setting up the necessary connections,
        permissions and integration parameters to allow data to flow seamlessly
        into your Snowflake environment. Read the our full guide on configuring
        Snowflake as a destination here. Before starting the implementation of a
        Cherre Egress for Snowflake, there is some information we need:
      </Text>
      <br />
      <FieldsContainer>
        <C.FieldBox>
          <C.FieldTitle>Account ID:</C.FieldTitle>
          <C.FieldInput
            size='small'
            value={accountID}
            onChange={(e) => setAccountID(e.target.value)}
            inputProps={{ 'data-testid': 'snowflake-account-id' }}
          />
        </C.FieldBox>
        <C.FieldBox>
          <C.FieldTitle>Region:</C.FieldTitle>
          <C.FieldSelect
            value={region}
            disabled={regions.length === 0}
            onChange={(e) => setRegion(e.target.value as string)}
            inputProps={{ 'data-testid': 'snowflake-region' }}
          >
            {regions.map((value) => (
              <MenuItem
                data-testid={`snowflake-region-${value}`}
                key={value}
                value={value}
              >
                {value}
              </MenuItem>
            ))}
          </C.FieldSelect>
        </C.FieldBox>
      </FieldsContainer>
      <C.Actions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={() =>
            addOrEditDestination(
              {
                provider: 'snowflake',
                providerConfig: [
                  { name: 'accountID', value: accountID },
                  { name: 'region', value: region },
                  {
                    name: 'enableEgress',
                    value: 'true',
                  },
                ],
              },
              () => {
                setUIState({ step: 'destinations-list' });
              }
            )
          }
          disabled={!accountID || !region}
          variant='contained'
        >
          Next
        </Button>
      </C.Actions>
    </Container>
  );
};

export default (props: SnowflakeProps) => (
  <Suspense fallback={<Skeleton />}>
    <Snowflake {...props} />
  </Suspense>
);
