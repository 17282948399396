export const namespace = 'organization';
const ns = (name) => `${namespace}/${name}`;

export const RESET = ns('RESET');

export const GET_ORGANIZATION_REQUEST = ns('GET_ORGANIZATION_REQUEST');
export const GET_ORGANIZATION_SUCCESS = ns('GET_ORGANIZATION_SUCCESS');
export const GET_ORGANIZATION_FAILED = ns('GET_ORGANIZATION_FAILED');

export const GET_USERS_REQUEST = ns('GET_USERS_REQUEST');
export const GET_USERS_SUCCESS = ns('GET_USERS_SUCCESS');
export const GET_USERS_FAILED = ns('GET_USERS_FAILED');

export const GET_USER_REQUEST = ns('GET_USER_REQUEST');
export const GET_USER_SUCCESS = ns('GET_USER_SUCCESS');
export const GET_USER_FAILED = ns('GET_USER_FAILED');

export const SAVE_USER_REQUEST = ns('SAVE_USER_REQUEST');
export const SAVE_USER_SUCCESS = ns('SAVE_USER_SUCCESS');
export const SAVE_USER_FAILED = ns('SAVE_USER_FAILED');

export const SHOW_ADD_USER_FORM = ns('SHOW_ADD_USER_FORM');
export const HIDE_ADD_USER_FORM = ns('HIDE_ADD_USER_FORM');

export const REENABLE_USER_REQUEST = ns('REENABLE_USER_REQUEST');
export const REENABLE_USER_SUCCESS = ns('REENABLE_USER_SUCCESS');
export const REENABLE_USER_FAILED = ns('REENABLE_USER_FAILED');

export const REENABLE_ORGANIZATION_REQUEST = ns(
  'REENABLE_ORGANIZATION_REQUEST'
);
export const REENABLE_ORGANIZATION_SUCCESS = ns(
  'REENABLE_ORGANIZATION_SUCCESS'
);
export const REENABLE_ORGANIZATION_FAILED = ns('REENABLE_ORGANIZATION_FAILED');

export const APPROVE_USER_REQUEST = ns('APPROVE_USER_REQUEST');
export const APPROVE_USER_SUCCESS = ns('APPROVE_USER_SUCCESS');
export const APPROVE_USER_FAILED = ns('APPROVE_USER_FAILED');

export const RESEND_EMAIL_REQUEST = ns('RESEND_EMAIL_REQUEST');
export const RESEND_EMAIL_SUCCESS = ns('RESEND_EMAIL_SUCCESS');
export const RESEND_EMAIL_FAILED = ns('RESEND_EMAIL_FAILED');
