import { combineReducers } from 'redux';

import getPermissionsReducers from './permissions/getReducers';
import getTablesReducers from './tables/getReducers';
import getFormsReducers from './forms/getReducers';
import organizationReqs from './organization/reducers';

export default combineReducers({
  ...getPermissionsReducers(),
  ...getTablesReducers(),
  ...getFormsReducers(),
  organizationReqs,
});
