import schemas from './schemas';
import * as types from './types';

import { showErrorPopupNotification } from 'src/products/shell/Notifications';

const getInitialState = () => ({
  fetchStatus: {},
  createStatus: {},
  data: {},
  title: '',
  endpoint: '',
});

const createFail = (formName, error) => {
  const message = `Failed to created new ${formName}.\n${error.message}`;
  showErrorPopupNotification(message);
};

const EditFail = (formName, error) => {
  const message = `Failed to Update ${formName}.\n${error.message}`;
  showErrorPopupNotification(message);
};

export const getFormReducer = (formName) => {
  const initialState = getInitialState();
  function reducer(state = initialState, action) {
    if (!action.formName) {
      return state;
    }

    if (action.formName !== formName) {
      return state;
    }

    switch (action.type) {
      case types.GET_ITEM_REQUEST: {
        return {
          ...state,
          fetchStatus: {
            value: 'LOADING',
          },
        };
      }

      case types.GET_ITEM_SUCCESS: {
        return {
          ...state,
          data: action.data,
          fetchStatus: {
            value: 'LOADED',
          },
        };
      }

      // case types.CREATE_ITEM_REQUEST: {
      //   return {
      //     ...state,
      //     fetchStatus: {
      //       value: 'LOADING'
      //     }
      //   };
      // }

      case types.CREATE_ITEM_SUCCESS: {
        return {
          ...state,
          data: action.data,
          fetchStatus: {
            value: 'LOADED',
          },
        };
      }

      // case types.UPDATE_ITEM_REQUEST: {
      //   return {
      //     ...state,
      //     fetchStatus: {
      //       value: 'LOADING'
      //     }
      //   };
      // }

      case types.UPDATE_ITEM_SUCCESS: {
        return {
          ...state,
          data: action.data,
          fetchStatus: {
            value: 'LOADED',
          },
        };
      }

      case types.CREATE_ITEM_FAILED: {
        createFail(action.formName, action.error);

        return {
          ...state,
          data: action.fieldValues,
          fetchStatus: {
            value: 'FAILED',
          },
        };
      }

      case types.UPDATE_ITEM_FAILED: {
        EditFail(action.formName, action.error);

        return {
          ...state,
          data: action.fieldValues,
          fetchStatus: {
            value: 'FAILED',
          },
        };
      }

      case types.RESET_ITEM: {
        return {
          ...state,
          data: {},
        };
      }

      default:
        return state;
    }
  }

  return reducer;
};

function getFormsReducers() {
  return Object.keys(schemas).reduce((memo, formName) => {
    memo[formName] = getFormReducer(formName);
    return memo;
  }, {});
}

export default getFormsReducers;
