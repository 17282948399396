import { PRODUCTS } from 'src/const';

export const signups = {
  id: 'signups',
  nameKey: 'email',
  endpoint: '/organizations/signups',
  entityName: 'Cherre Signup Requests',
  actions: ['approve'],
  orderBy: 'createdAt',
  order: 'desc',
  filters: {
    approved: {
      label: 'Approved',
      mask: 'boolean',
      value: '',
      options: [false, true],
    },
  },
  columns: [
    {
      field_name: 'firstName',
      column_label: 'First Name',
      mask: '',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'lastName',
      column_label: 'Last Name',
      mask: '',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'email',
      column_label: 'Email Address',
      mask: '',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'organizationName',
      column_label: 'Organization',
      mask: '',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'coreProspect',
      column_label: PRODUCTS.EXPLORE,
      mask: 'boolean',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'coreConnect',
      column_label: PRODUCTS.CONNECT,
      mask: 'boolean',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'approved',
      column_label: 'Approved',
      mask: 'boolean',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'createdAt',
      column_label: 'Created At',
      mask: 'date',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
  ],
};
