export const group = {
  formName: 'group',
  title: '${this.params.operation} Group',
  backUrl: '/admin/groups',
  endpoint: '/groups/${this.params.id}',
  createEndpoint: '/groups',
  fields: [
    {
      name: 'name',
      interface: 'textbox',
      type: 'string',
      label: 'Group Name',
      hint: '',
      required: true,
      validation: null,
    },
  ],
};
