export const namespace = 'admin-organizations';
const ns = (name) => `${namespace}/${name}`;

export const DELETE_ITEM_REQUEST = ns('DELETE_ITEM_REQUEST');
export const DELETE_ITEM_SUCCESS = ns('DELETE_ITEM_SUCCESS');
export const DELETE_ITEM_FAILED = ns('DELETE_ITEM_FAILED');

export const GET_ITEMS_REQUEST = ns('GET_ITEMS_REQUEST');
export const GET_ITEMS_SUCCESS = ns('GET_ITEMS_SUCCESS');
export const GET_ITEMS_FAILED = ns('GET_ITEMS_FAILED');

export const GET_ITEMS_REQUEST_COUNT = ns('GET_ITEMS_REQUEST_COUNT');
export const GET_ITEMS_SUCCESS_COUNT = ns('GET_ITEMS_SUCCESS_COUNT');
export const GET_ITEMS_FAILED_COUNT = ns('GET_ITEMS_FAILED_COUNT');

export const CHANGE_SEARCH_VALUE = ns('CHANGE_SEARCH_VALUE');
export const CHANGE_ORDER = ns('CHANGE_ORDER');
export const CHANGE_PAGE = ns('CHANGE_PAGE');
export const CHANGE_ROWS_PER_PAGE = ns('CHANGE_ROWS_PER_PAGE');
export const SET_FILTER_VALUE = ns('SET_FILTER_VALUE');
