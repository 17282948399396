import React, { createContext, useCallback, useContext, useState } from 'react';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay';
import { useDestinationsQuery } from './__generated__/useDestinationsQuery.graphql';
import { useDestinationsAddDestinationMutation } from './__generated__/useDestinationsAddDestinationMutation.graphql';
import { useDestinationsEditDestinationMutation } from './__generated__/useDestinationsEditDestinationMutation.graphql';
import { useAppContext } from '@cherre-frontend/core';

/**
 * Custom hook to manage destinations state.
 *
 * @returns {Object} An object containing the destinations state.
 * @returns {Array} destinations - The list of destinations.
 */

const fetchKeyContext = createContext<
  [number, React.Dispatch<React.SetStateAction<number>>]
>([Math.random(), () => {}]);

export const FetchKeyContextProvider: React.FC = ({ children }) => {
  const fetchKeyState = useState(Math.random());
  return (
    <fetchKeyContext.Provider value={fetchKeyState}>
      {children}
    </fetchKeyContext.Provider>
  );
};

export const useDestinations = () => {
  const [fetchKey, setFetchKey] = useContext(fetchKeyContext);
  const { showSnackbar } = useAppContext();
  const { destinations } = useLazyLoadQuery<useDestinationsQuery>(
    graphql`
      query useDestinationsQuery {
        destinations {
          name
          provider
          displayName
          config
        }
      }
    `.default,
    {},
    { fetchKey: fetchKey, fetchPolicy: 'network-only' }
  );
  const [addDestination, adding] =
    useMutation<useDestinationsAddDestinationMutation>(
      graphql`
        mutation useDestinationsAddDestinationMutation(
          $provider: String!
          $providerConfig: [VariableInput]
          $displayName: String
        ) {
          createDestination(
            provider: $provider
            providerConfig: $providerConfig
            displayName: $displayName
          ) {
            name
            provider
            displayName
            config
          }
        }
      `.default
    );
  const [editDestination, editing] =
    useMutation<useDestinationsEditDestinationMutation>(
      graphql`
        mutation useDestinationsEditDestinationMutation(
          $provider: String!
          $providerConfig: [VariableInput]
          $displayName: String
        ) {
          updateDestination(
            provider: $provider
            providerConfig: $providerConfig
            displayName: $displayName
          ) {
            name
            provider
            displayName
            config
          }
        }
      `.default
    );
  const addOrEditDestination = useCallback(
    async (
      destination: useDestinationsAddDestinationMutation['variables'],
      onComplete: () => void
    ) => {
      return new Promise<void>((resolve) => {
        const onCompleted = (type: 'added' | 'updated') => {
          resolve();
          onComplete();
          setFetchKey(Math.random());
          showSnackbar({
            message: `Destination ${type} successfully`,
            type: 'success',
          });
        };
        const onError = (type: 'add' | 'edit') => {
          resolve();
          showSnackbar({
            message: `Failed to ${type} destination`,
            type: 'error',
          });
        };
        // Add destination to the list of destinations
        if (destinations.some((d) => d.provider === destination.provider)) {
          editDestination({
            variables: destination,
            onCompleted: () => onCompleted('updated'),
            onError: () => onError('edit'),
          });
        } else {
          addDestination({
            variables: destination,
            onCompleted: () => onCompleted('added'),
            onError: () => onError('add'),
          });
        }
      });
    },
    [destinations, addDestination, editDestination]
  );

  return { destinations, addOrEditDestination, loading: adding || editing };
};
