import { isAddButtonDisabledForNonAdmin } from 'src/products/admin/redux/tables/schemas/isAddButtonDisabled';

export const datatables = {
  id: 'datatables',
  nameKey: 'name',
  entityName: 'datatable',
  backUrl: '/admin/organizations/${this.params.organization_id}/datasets',
  addItemUrl:
    '/admin/organizations/${this.params.organization_id}/datasets/${this.params.dataset_id}/new',
  isAddButtonDisabled: isAddButtonDisabledForNonAdmin,
  endpoint:
    '/organizations/${this.params.organization_id}/datasets/${this.params.dataset_id}/datatables',
  actions: ['edit'],
  filters: {
    isDisabled: {
      label: 'Active',
      mask: 'reverseBoolean',
      value: '',
      options: [false, true],
    },
  },
  columns: [
    {
      field_name: 'name',
      column_label: 'Name',
      mask: '',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'description',
      column_label: 'Description',
      mask: '',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'isDisabled',
      column_label: 'Active',
      mask: 'reverseBoolean',
      sortable: true,
      col_type: 'normal',
    },
  ],
};
