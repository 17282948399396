import { usePost, usePut } from '@cherre-frontend/data-fetching';
import type { UserFormValues } from 'src/products/admin/pages/User/Form/types';
import { getUserSelector } from './useEditedUserFormValues';
import { useHistory } from 'react-router';

export const useCreateOrUpdateUser = (
  organizationId: string,
  userId: string | undefined,
  backUrl: string
) => {
  const history = useHistory();
  const createUser = usePost<UserFormValues, void>(
    `/api/v1/organizations/${organizationId}/users`,
    {
      onCompleted: (_, context) => {
        context.showSnackbar({
          type: 'success',
          message: 'Successfully created user.',
        });
        history.push(backUrl);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any, context) => {
        // list messages from the server
        const errorMessages: string[] =
          error?.error?.response?.data?.errors?.map((e) =>
            (e.message as string).toUpperCase()
          ) || [];
        // handle the case where the user already exists
        if (errorMessages.includes('USER ALREADY EXISTS')) {
          const email = JSON.parse(error?.error?.config?.data)?.email;
          context.showSnackbar({
            type: 'error',
            message: `User '${email}' already exists`,
          });
        } else {
          context.showSnackbar({
            type: 'error',
            message: 'Failed to add user',
          });
        }
      },
    }
  );

  const updateUser = usePut<UserFormValues, void>(
    `/api/v1/organizations/${organizationId}/users/${userId}`,
    {
      onCompleted: (_, context) => {
        if (userId) {
          context.recoil.reset(getUserSelector(organizationId, userId));
        }
        context.showSnackbar({
          type: 'success',
          message: 'Successfully updated user.',
        });
        history.push(backUrl);
      },
    }
  );

  return userId ? updateUser : createUser;
};
