export const dashboardsForUser = {
  formName: 'dashboards',
  title: 'User Dashboards',
  backUrl: '/customerAdmin/users',
  noId: true,
  submitText: 'Update',
  endpoint: '/organizations/users/${this.params.user_id}/dashboards-form-data',
  // createEndpoint: '/organizations',
  fields: [
    {
      name: 'User Dashboards',
      interface: 'checkbox',
      type: 'boolean',
      label: 'Organization Dashboards',
      hint: 'test',
      required: false,
      validation: null,
    },
  ],
};
