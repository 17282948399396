import * as types from './types';
import schemas from './schemas';
import fillTemplate from 'src/utils/fillTemplate';

export function getPermissions(schemaId, params) {
  return async (dispatch) => {
    return dispatch({
      url: fillTemplate(schemas[schemaId].endpoint, { params }),
      types: [
        types.GET_PERMISSIONS_REQUEST,
        types.GET_PERMISSIONS_SUCCESS,
        types.GET_PERMISSIONS_FAILED,
      ],
      dataToDispatch: { schemaId },
    });
  };
}

const getPermissionsActions = (schemaId) => {
  return {
    getPermissions: getPermissions.bind(null, schemaId),
  };
};

export default getPermissionsActions;
