import { useMemo } from 'react';
import { getSelector, useCherreValue } from '@cherre-frontend/data-fetching';
import type { Group } from 'src/products/admin/types/group';
import { useOrganizationAuthzVersionName } from './useOrganizationAuthzVersionName';

const getGroupsSelector = getSelector<Group[]>({
  url: `/api/v1/groups`,
});

export const useCherreRolesOptions = (
  organizationId: string
): { label: string; value: string }[] => {
  const authZVersionName = useOrganizationAuthzVersionName(organizationId);
  const groups = useCherreValue(getGroupsSelector);

  return useMemo(() => {
    if (!groups || !authZVersionName) {
      return [];
    }

    return groups
      .filter((group) => group.authz_version?.name === authZVersionName)
      .sort((groupA, groupB) => groupA.name.localeCompare(groupB.name))
      .map(({ name, description }) => ({ label: description, value: name }));
  }, [groups, authZVersionName]);
};
