import React from 'react';
import getFormComponent from '../../getFormComponent';
import schemas from 'src/products/admin/redux/forms/schemas';
import useFields from './useFields';
import { getOrganizationLookerDashboards } from 'src/products/admin/redux/dashboards/actions';

const Dashboard = (props) => {
  const { fields, isLoading } = useFields(
    props?.match?.params?.organization_id,
    getOrganizationLookerDashboards
  );

  const Component = getFormComponent({
    ...schemas.dashboardsForUser,
    fields,
  });

  if (isLoading) {
    return <span>Loading...</span>;
  }

  return <Component {...props} />;
};

export default Dashboard;
