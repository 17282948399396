export const powerBiConfiguration = {
  formName: 'power-bi-configuration',
  title: null,
  backUrl: null,
  endpoint: '/powerbi/${this.params.id}/configure',
  createEndpoint: null,
  usesSnakeCase: false,
  fields: [
    {
      name: 'id',
      interface: 'hidden',
      type: 'integer',
      label: '',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'Title',
      interface: 'label',
      type: 'string',
      label: `<h2>Power BI Organization Settings</h2>`,
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'powerBiApiUrl',
      interface: 'textbox',
      type: 'string',
      label: 'API URL',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'powerBiTenantId',
      interface: 'textbox',
      type: 'string',
      label: 'Tenant ID',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'powerBiClientId',
      interface: 'textbox',
      type: 'string',
      label: 'Client ID',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'powerBiClientSecret',
      interface: 'textbox',
      type: 'string',
      label: 'Client Secret',
      hint: '',
      required: false,
      validation: null,
    },
  ],
};
