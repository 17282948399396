import React from 'react';
import { Field } from 'react-final-form';
import { TextField } from '@cherre-frontend/ui';

interface UserFormTextFieldProps {
  name: string;
  label: string;
  disabled?: boolean;
  validator?: (value: string) => string | undefined;
}

export const UserFormTextField = (props: UserFormTextFieldProps) => {
  const { label, name, disabled, validator } = props;

  return (
    <Field name={name} validate={validator}>
      {({ input, meta: { error, touched } }) => (
        <TextField
          {...input}
          required
          variant='standard'
          className='suspend'
          label={label}
          disabled={disabled}
          error={touched && error}
          helperText={touched && error ? error : undefined}
        />
      )}
    </Field>
  );
};
