import axios from 'axios';
import { selectorFamily } from 'recoil';
import { Dashboard } from 'src/products/data-connections/types';

const getField = (dashboard: Dashboard) => ({
  style: { width: '90%', marginTop: 0, marginBottom: 0 },
  name: `id-${dashboard.slug}`,
  interface: 'checkbox',
  type: 'boolean',
  label: dashboard.name,
});

export const fieldsSelector = selectorFamily<
  ReturnType<typeof getField>[],
  string
>({
  key: 'ADMIN-DASHBOARDS/DASHBOARDS-SELECTOR',
  get: (organizationId) => async () => {
    const url = `/api/v1/organizations/${organizationId}/looker_dashboards`;
    const response = await axios.get<Dashboard[]>(url);
    return response.data.map(getField);
  },
});
