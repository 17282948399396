import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TablePage from 'src/components/TablePage';
import getActions from 'src/products/admin/redux/tables/getActions';
import fillTemplate from 'src/utils/fillTemplate';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router';
import { searchParamsSelector, refine } from '@cherre-frontend/data-fetching';
import ReassignUserModal from 'src/products/data-submission-portal/components/ReassignUserModal';
import { EnsurePermissions } from 'src/utils/routing/EnsurePermissions';

export const reassignUserIdSearchParam = searchParamsSelector({
  key: 'reassign-user-id',
  refine: refine.withDefault(refine.nullable(refine.number()), null),
  routes: ['/customerAdmin/users', '/admin/organizations/:id/users'],
});

export default function (schema, local = true) {
  const {
    deleteRow,
    getRows,
    sort,
    search,
    setFilterValue,
    changePage,
    changeRowsPerPage,
  } = getActions(schema.id, local);

  class TableComponent extends React.Component {
    static childContextTypes = {
      schema: PropTypes.object,
    };

    getChildContext() {
      return { schema };
    }

    componentDidMount() {
      const params = this.props.params || this.props.match.params;
      this.props.getRows(params);
      !local && this.props.getRows(params, true);
    }

    componentWillReceiveProps(nextProps) {
      if (
        nextProps.store.deleteStatus.value === 'LOADED' &&
        this.props.store.deleteStatus.value === 'LOADING'
      ) {
        const params = this.props.params || this.props.match.params;
        this.props.getRows(params);
        !local && this.props.getRows(params, true);
      }
    }

    render() {
      const {
        store,
        stores,
        sort,
        deleteRow,
        search,
        setFilterValue,
        changePage,
        changeRowsPerPage,
      } = this.props;
      const params = this.props.params || this.props.match.params;

      return (
        <>
          <TablePage
            refresh={() => {
              this.props.getRows(params);
              !local && this.props.getRows(params, true);
            }}
            params={params}
            onAddButtonClick={
              schema.addItemUrl
                ? () =>
                    this.props.push(fillTemplate(schema.addItemUrl, { params }))
                : null
            }
            onBackButtonClick={
              schema.backUrl
                ? () => {
                    this.props.push(fillTemplate(schema.backUrl, { params }));
                  }
                : null
            }
            onDelete={deleteRow.bind(null, params)}
            onSort={sort.bind(null, params)}
            onSearch={search.bind(null, params)}
            onFilter={setFilterValue.bind(null, params)}
            onChangePage={changePage.bind(null, params)}
            onChangeRowsPerPage={changeRowsPerPage.bind(null, params)}
            store={store}
            stores={stores}
            isAddButtonDisabled={schema.isAddButtonDisabled}
            actions={schema.actions?.length > 0}
            local={local}
            total={local ? undefined : store.total || 0}
          />
          {schema.actions.includes('dsp-reassign-user') ? (
            <EnsurePermissions permissions={['DSPAdmin']} renderError={false}>
              <ReassignUserModal selector={reassignUserIdSearchParam} />
            </EnsurePermissions>
          ) : null}
        </>
      );
    }
  }

  TableComponent.propTypes = {
    params: PropTypes.object,
    store: PropTypes.object,
    stores: PropTypes.object,
    dispatch: PropTypes.func,
    sort: PropTypes.func,
    search: PropTypes.func,
    setFilterValue: PropTypes.func,
    getRows: PropTypes.func,
    deleteRow: PropTypes.func,
    changePage: PropTypes.func,
    changeRowsPerPage: PropTypes.func,
  };
  function mapStateToProps(state) {
    const {
      adminPanel: { [schema.id]: store },
    } = state;

    return { store, stores: state };
  }

  return connect(mapStateToProps, {
    deleteRow,
    getRows,
    sort,
    search,
    setFilterValue,
    changePage,
    changeRowsPerPage,
    push,
  })(withRouter(TableComponent));
}
