import React from 'react';
import { useParams } from 'react-router';
import { Form } from 'react-final-form';
import { useAppContext } from '@cherre-frontend/core';
import { Typography } from '@cherre-frontend/ui';
import { email, required } from 'src/products/shell/forms/validators';
import type { UserFormRouterParams, UserFormValues } from './types';
import { SubmitButtonStyled, UserFormStyled } from './UserForm.styled';
import { useUserFormOrganizationId } from './hooks/useUserFormOrganizationId';
import { useCherreRolesOptions } from './hooks/useCherreRolesOptions';
import { useCreateOrUpdateUser } from './hooks/useCreateOrUpdateUser';
import { useEditedUserFormValues } from './hooks/useEditedUserFormValues';
import { UserFormTextField } from './components/UserFormTextField';
import { UserFormSelectField } from './components/UserFormSelectField';
import { UserFormCheckboxField } from './components/UserFormCheckboxField';
import { useSelector } from 'react-redux';
import { isAllowed } from 'src/utils/routing';

const permissionsAllowedToEditAllFields = [
  'UserManagement',
  'CherreAdmin',
  'CherreAdminReadOnly',
  'IDAdmin',
];

const emptyInitialValues: UserFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  role: 'regular',
  skipWelcomeEmail: false,
};

export const UserForm = ({ backUrl }: { backUrl: string }) => {
  const context = useAppContext();
  const params = useParams<UserFormRouterParams>();
  const isEditing = Boolean(params.id);
  const organizationId = useUserFormOrganizationId();
  const cherreRolesOptions = useCherreRolesOptions(organizationId);
  const createOrUpdateUser = useCreateOrUpdateUser(
    organizationId,
    params.id,
    backUrl
  );
  const currentUser = useSelector((state) => state.user.profile.value);
  const editedUserFormValues = useEditedUserFormValues(
    organizationId,
    params.id
  );

  const shouldDisableEditingFieldsForRoleAdmin =
    isEditing &&
    !isAllowed(
      currentUser?.permissions || [],
      permissionsAllowedToEditAllFields
    );

  const initialValues: UserFormValues = {
    ...emptyInitialValues,
    ...editedUserFormValues,
  };

  const onSubmit = async (values: UserFormValues) => {
    try {
      await createOrUpdateUser(values);
    } catch (err) {
      const message = isEditing
        ? 'Failed to update user.'
        : 'Failed to created new user.';
      context.showSnackbar({ type: 'error', message });
    }
  };

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, submitting, pristine, valid }) => (
        <UserFormStyled noValidate autoComplete='off' onSubmit={handleSubmit}>
          <Typography className='suspend'>
            Fields marked with asterick (*) are mandatory.
          </Typography>

          <UserFormTextField
            name='firstName'
            label='First Name'
            validator={required}
            disabled={shouldDisableEditingFieldsForRoleAdmin}
          />

          <UserFormTextField
            name='lastName'
            label='Last Name'
            validator={required}
            disabled={shouldDisableEditingFieldsForRoleAdmin}
          />

          <UserFormTextField
            name='email'
            label='Email Address'
            disabled={isEditing}
            validator={(value) => required(value) || email(value)}
          />

          <UserFormSelectField
            name='role'
            label='Cherre Role'
            validator={required}
            options={cherreRolesOptions}
          />

          {!isEditing && (
            <UserFormCheckboxField
              name='skipWelcomeEmail'
              label='Skip Welcome Email'
            />
          )}

          <SubmitButtonStyled
            color='primary'
            type='submit'
            variant='contained'
            className='suspend'
            loading={submitting}
            disabled={pristine || !valid}
          >
            {isEditing ? 'Update' : 'Create'}
          </SubmitButtonStyled>
        </UserFormStyled>
      )}
    </Form>
  );
};
