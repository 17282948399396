import { coreBiConfiguration } from './core-bi-configuration';
import { powerBiConfiguration } from './power-bi-configuration';
import { coreProspectConfiguration } from './core-prospect-configuration';
import { dataSubmissionPortalConfiguration } from './data-submission-portal-configuration';
import { connectorsConfiguration } from './connectors-configuration';
import { coreSchemaConfiguration } from './core-schema-configuration';
import { customerUser } from './CustomerUser';
import { dashboards } from './dashboards';
import { dashboardsForUser } from './dashboardsForUser';
import { dataset } from './dataset';
import { datatable } from './datatable';
import { datasetDashboards } from './dataset-dashboards';
import { generalConfiguration } from './general-configuration';
import { globalSettings } from './global-setting';
import { organization } from './organization';
import { user } from './user';
import { group } from './group';
import { userFirstTimeLogin } from './user-first-time-login';

export default {
  'connectors-configuration': connectorsConfiguration,
  'core-prospect-configuration': coreProspectConfiguration,
  'core-bi-configuration': coreBiConfiguration,
  'power-bi-configuration': powerBiConfiguration,
  'core-schema-configuration': coreSchemaConfiguration,
  'data-submission-portal-configuration': dataSubmissionPortalConfiguration,
  customerUser,
  dashboards,
  dashboardsForUser,
  dataset,
  datatable,
  'global-settings': globalSettings,
  group,
  'general-configuration': generalConfiguration,
  'dataset-dashboards': datasetDashboards,
  organization,
  user,
  'user-first-time-login': userFirstTimeLogin,
};
