import { styled } from '@cherre-frontend/ui';
import ButtonProgress from 'src/components/ButtonProgress';

export const UserFormStyled = styled('form')`
  max-width: 466px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 15px;
`;

export const SubmitButtonStyled = styled(ButtonProgress)`
  margin: 15px 0;
  min-width: 115px;
`;
