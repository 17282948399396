import { createContext, useContext } from 'react';
import { z } from 'zod';
import { State } from './utils';

const AddDestinationSchema = z.object({
  step: z.literal('add-destination'),
  type: z.string().optional(),
});

const DestinationsListSchema = z.object({
  step: z.literal('destinations-list'),
});

const UIStateSchema = z.union([AddDestinationSchema, DestinationsListSchema]);
export type UIState = z.infer<typeof UIStateSchema>;

const UIStateCtx = createContext<State<UIState> | null>(null);

/**
 * Custom hook to manage UI state.
 *
 * @returns {Object} An object containing the UI state.
 */
export const useUIState = () => {
  const ctx = useContext(UIStateCtx);
  if (!ctx) {
    throw new Error('useUIState must be used within a UIStateProvider');
  }
  return ctx;
};

export const UIStateProvider = UIStateCtx.Provider;
