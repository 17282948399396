import { isAddButtonDisabledForNonAdmin } from 'src/products/admin/redux/tables/schemas/isAddButtonDisabled';

export const organizations = {
  id: 'organizations',
  nameKey: 'name',
  entityName: 'organization',
  backUrl: undefined,
  addItemUrl: '/admin/organizations/new',
  isAddButtonDisabled: isAddButtonDisabledForNonAdmin,
  endpoint: '/organizations',
  actions: [
    'edit',
    'core-bi-configuration',
    'core-bi-dashboards',
    'core-bi-workspaces',
    'permissions',
    'disable',
    're-enable-organization',
    'organization-settings',
  ],
  filters: {
    disabled: {
      label: 'Active',
      mask: 'reverseBoolean',
      value: '',
      options: [false, true],
    },
  },
  columns: [
    {
      field_name: 'name',
      column_label: 'Organization Name',
      mask: '',
      sortable: true,
      col_type: 'normal',
      additional_props: {
        url_template_string: '/admin/organizations/${this.row.id}',
      },
    },
    {
      field_name: '_count.datasets',
      column_label: 'Datasets',
      mask: '',
      sortable: true,
      col_type: 'internalLink',
      additional_props: {
        url_template_string: '/admin/organizations/${this.row.id}/datasets',
      },
    },
    {
      field_name: '_count.users',
      column_label: 'Users',
      mask: '',
      sortable: true,
      col_type: 'internalLink',
      additional_props: {
        url_template_string: '/admin/organizations/${this.row.id}/users',
      },
    },
    {
      field_name: 'description',
      column_label: 'Description',
      mask: '',
      sortable: false,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'hasuraRole',
      column_label: 'Hasura role name for coreexplore/corequery',
      mask: '',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'apiHasuraRole',
      column_label: 'Hasura role name for coreschema/API',
      mask: '',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'hasuraOrgName',
      column_label: 'Hasura organization name',
      mask: '',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'themeName',
      column_label: 'Theme',
      mask: '',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'additionalProps.organization_looker_email',
      column_label: 'Looker email',
      mask: '',
      sortable: false,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'planType',
      column_label: 'Plan type',
      mask: '',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'planExpiresAt',
      column_label: 'Plan Expiration Date',
      mask: 'date',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'disabled',
      column_label: 'Active',
      mask: 'reverseBoolean',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'lastModified',
      column_label: 'Last Updated',
      mask: 'timeago',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
  ],
};
