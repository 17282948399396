export const getLookerDashboards = (organizationId) => {
  return async (dispatch) => {
    return dispatch({
      url: `/organizations/${organizationId}/looker_dashboards`,
      types: [],
    });
  };
};

export const getPowerBiReports = (organizationId) => {
  return async (dispatch) => {
    return dispatch({
      url: `/organizations/${organizationId}/powerbi-reports`,
      types: [],
    });
  };
};

export const getOrganizationLookerDashboards = (organizationId) => {
  return async (dispatch) => {
    return dispatch({
      url:
        `/organizations/dashboards` +
        (organizationId ? `?organization_id=${organizationId}` : ''),
      types: [],
    });
  };
};
