export const dataset = {
  formName: 'dataset',
  title: '${this.params.operation} Dataset',
  backUrl: '/admin/organizations/${this.params.organization_id}/datasets',
  endpoint: '/organizations/datasets/${this.params.id}',
  createEndpoint: '/organizations/${this.params.organization_id}/datasets',
  fields: [
    {
      name: 'name',
      interface: 'textbox',
      type: 'string',
      label: 'Dataset Name',
      hint: '',
      required: true,
      validation: null,
    },
    {
      name: 'description',
      interface: 'textbox',
      type: 'string',
      label: 'Dataset Description',
      hint: '',
      required: true,
      validation: null,
    },
    {
      name: 'isDisabled',
      interface: 'checkbox',
      type: 'boolean',
      label: 'Disabled',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'isLightningConnection',
      interface: 'checkbox',
      type: 'boolean',
      label: 'Lightning Connection',
      hint: '',
      required: false,
      validation: null,
    },
  ],
};
