import * as types from './types';

export function getOrganization(organizationId, countEnabledUsersOnly = null) {
  return async (dispatch) => {
    return dispatch({
      url: `/organizations/${organizationId}${
        countEnabledUsersOnly
          ? '?countEnabledUsersOnly=' + countEnabledUsersOnly
          : ''
      }`,
      method: 'get',
      types: [
        types.GET_ORGANIZATION_REQUEST,
        types.GET_ORGANIZATION_SUCCESS,
        types.GET_ORGANIZATION_FAILED,
      ],
    });
  };
}

export function getUser(id) {
  return (dispatch, getState) => {
    const organizationId = getState().user.profile.value.organizationId;

    return dispatch({
      url: `/organizations/${organizationId}/users/${id}`,
      method: 'get',
      types: [
        types.GET_USER_REQUEST,
        types.GET_USER_SUCCESS,
        types.GET_USER_FAILED,
      ],
    });
  };
}

export function searchUsers(value) {
  return (dispatch) => {
    return dispatch({
      url: '/users/by-organization',
      method: 'get',
      query: { v: value },
      types: [undefined, undefined, undefined],
    });
  };
}

export function getUsers() {
  return (dispatch, getState) => {
    const organizationId = getState().user.profile.value.organizationId;

    return dispatch({
      url: `/organizations/${organizationId}/users`,
      method: 'get',
      types: [
        types.GET_USERS_REQUEST,
        types.GET_USERS_SUCCESS,
        types.GET_USERS_FAILED,
      ],
    });
  };
}

export function getUsersByOrganization(organizationId) {
  return (dispatch) => {
    return dispatch({
      url: `/organizations/${organizationId}/users`,
      method: 'get',
      types: [undefined, undefined, undefined],
    });
  };
}

export function reset() {
  return {
    type: types.RESET,
  };
}

export function saveUser(user) {
  return (dispatch, getState) => {
    const userId = user.id;
    const organizationId = getState().user.profile.value.organizationId;

    return dispatch({
      url: userId
        ? `/organizations/${organizationId}/users/${userId}`
        : '/organizations/${organizationId}/users',
      method: userId ? 'put' : 'post',
      send: user,
      types: [
        types.SAVE_USER_REQUEST,
        types.SAVE_USER_SUCCESS,
        types.SAVE_USER_FAILED,
      ],
    });
  };
}

export function reEnableOrganization(organizationId) {
  return (dispatch) => {
    return dispatch({
      url: `/organizations/${organizationId}/re-enable`,
      method: 'post',
      types: [
        types.REENABLE_ORGANIZATION_REQUEST,
        types.REENABLE_ORGANIZATION_SUCCESS,
        types.REENABLE_ORGANIZATION_FAILED,
      ],
    });
  };
}

export function reEnableUser(user) {
  return (dispatch) => {
    const userId = user.id;
    const organizationId = user.organizationId;

    return dispatch({
      url: `/organizations/${organizationId}/users/${userId}/re-enable`,
      method: 'post',
      send: user,
      types: [
        types.REENABLE_USER_REQUEST,
        types.REENABLE_USER_SUCCESS,
        types.REENABLE_USER_FAILED,
      ],
    });
  };
}

export function approveUserSignup(signupItem) {
  return (dispatch) => {
    return dispatch({
      url: `/organizations/approve-signup`,
      method: 'post',
      send: signupItem,
      types: [
        types.APPROVE_USER_REQUEST,
        types.APPROVE_USER_SUCCESS,
        types.APPROVE_USER_FAILED,
      ],
    });
  };
}

export function resendEmail(item) {
  return (dispatch) => {
    return dispatch({
      url: `/organizations/resend-welcome-email`,
      method: 'post',
      send: item,
      types: [
        types.RESEND_EMAIL_REQUEST,
        types.RESEND_EMAIL_SUCCESS,
        types.RESEND_EMAIL_FAILED,
      ],
    });
  };
}

export function disableApiKey(apiKeyId) {
  return (dispatch) => {
    return dispatch({
      url: `/api-keys/${apiKeyId}/disable`,
      method: 'post',
      send: {},
      types: [],
    });
  };
}

export function editApiKey(apiKeyId, send) {
  return (dispatch) => {
    return dispatch({
      url: `/api-keys/${apiKeyId}`,
      method: 'put',
      send,
      types: [],
    });
  };
}
