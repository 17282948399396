import { isAddUserButtonDisabled } from 'src/products/admin/redux/tables/schemas/isAddButtonDisabled';

export const customer_users = {
  id: 'customer_users',
  nameKey: 'email',
  entityName: 'user',
  backUrl: null,
  addItemUrl: '/customerAdmin/users/new',
  endpoint: '/organizations/${this.params.id}/users',
  emulateEndpoint:
    '/api/v1/organizations/${this.row.organizationId}/users/${this.row.id}/emulate',
  isAddButtonDisabled: async (params) => isAddUserButtonDisabled(false)(params),
  actions: [
    'edit-user-dashboards',
    'core-bi-workspaces',
    'edit',
    'emulate',
    'dsp-reassign-user',
    'productAccess',
    'permissions',
    'disable',
    're-enable',
    'app-configuration',
  ],
  columns: [
    {
      field_name: 'firstName',
      column_label: 'First Name',
      mask: '',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'lastName',
      column_label: 'Last Name',
      mask: '',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'email',
      column_label: 'Email Address',
      mask: '',
      sortable: true,
      col_type: 'normal',
      additional_props: {
        url_template_string:
          '/admin/organizations/${this.params.id}/users/${this.row.id}',
      },
    },
    {
      field_name: 'role_description',
      column_label: 'Role',
      mask: '',
      sortable: false,
      col_type: 'normal',
      additional_props: { lookupName: 'user_roles' },
    },
    {
      field_name: 'disabled',
      column_label: 'Disabled',
      mask: 'boolean',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'lastModified',
      column_label: 'Last Updated',
      mask: 'timeago',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
    {
      field_name: 'lastSeen',
      column_label: 'Last Seen',
      mask: 'date',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
  ],
};
