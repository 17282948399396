import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import type { UserFormRouterParams } from 'src/products/admin/pages/User/Form/types';

export const useUserFormOrganizationId = (): string => {
  const params = useParams<UserFormRouterParams>();
  const currentUserOrganizationId = useSelector(
    (state) => state.user.profile.value.organizationId
  );

  return params.organization_id
    ? params.organization_id
    : String(currentUserOrganizationId);
};
