import { useMemo } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import type { useRegionsQuery } from './__generated__/useRegionsQuery.graphql';

export const useRegions = () => {
  const { configmap } = useLazyLoadQuery<useRegionsQuery>(
    graphql`
      query useRegionsQuery {
        configmap(name: "destination-snowflake") {
          name
          data {
            name
            value
          }
        }
      }
    `.default,
    {}
  );
  return useMemo(() => {
    const snowflakeConfig = configmap.find(
      (c) => c.name === 'destination-snowflake'
    );
    if (!snowflakeConfig) {
      return [];
    }
    const regionConfig = snowflakeConfig.data.find((d) => d.name === 'region');
    if (!regionConfig) {
      return [];
    }
    return JSON.parse(regionConfig.value) as string[];
  }, [configmap]);
};
