export const globalSettings = {
  formName: 'global-settings',
  title: 'Global Settings',
  description:
    'Settings used to control the frontend. Do not put secret values here as they will be exposed to all users.',
  backUrl: null,
  noId: true,
  endpoint: '/global-settings',
  createEndpoint: null,
  fields: [
    {
      name: 'bing_maps_key',
      interface: 'textbox',
      type: 'string',
      label: 'Bing Maps Key',
      required: true,
      validation: null,
    },
  ],
};
