import { isAllowed } from 'src/utils/routing';

export const isNotCherreAdmin = ({ stores }) => {
  return !stores?.user?.profile?.value?.permissions?.includes('CherreAdmin');
};

export const isAddButtonDisabledForNonAdmin = ({ stores, callback }) => {
  callback(isNotCherreAdmin({ stores }));
};

export const isAddUserButtonDisabled =
  (adminOnly = false) =>
  async ({ stores, callback, messageCallback, params }) => {
    let { id } = params;
    if (!id) {
      id = stores.user.profile.value.organizationId;
    }

    if (adminOnly && isNotCherreAdmin({ stores })) {
      callback(true);
      return;
    }

    const userPermissions = stores?.user?.profile?.value?.permissions || [];
    const allowedPermissions = ['CherreAdmin', 'UserManagement', 'IDAdmin'];
    if (!isAllowed(userPermissions, allowedPermissions)) {
      return callback(true);
    }

    const organization = stores?.organization;

    let result = false;
    if (organization?.additionalProps?.max_users_count) {
      result =
        organization?.usersCount >=
        organization?.additionalProps?.max_users_count;
    }
    callback(result);

    const tooltipMessage =
      'You have reached your user limit. Please reach out to your account representative to add more seats to your license.';

    result ? messageCallback(tooltipMessage) : messageCallback('');
  };
