/**
 * @generated SignedSource<<4bc3b0b9eaf97d98c7b80969402a1768>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useRegionsQuery$variables = {};
export type useRegionsQuery$data = {
  readonly configmap: ReadonlyArray<{
    readonly data: ReadonlyArray<{
      readonly name: string;
      readonly value: string;
    }>;
    readonly name: string;
  }>;
};
export type useRegionsQuery = {
  response: useRegionsQuery$data;
  variables: useRegionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "name",
        "value": "destination-snowflake"
      }
    ],
    "concreteType": "ConfigMap",
    "kind": "LinkedField",
    "name": "configmap",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Data",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "configmap(name:\"destination-snowflake\")"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useRegionsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useRegionsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4dcf2f995a3169c6e762374ccbbec420",
    "id": null,
    "metadata": {},
    "name": "useRegionsQuery",
    "operationKind": "query",
    "text": "query useRegionsQuery {\n  configmap(name: \"destination-snowflake\") {\n    name\n    data {\n      name\n      value\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4249ba3ef17a77ca04bbe079bd7653ed";

export default node;
