export const dataSubmissionPortalConfiguration = {
  formName: 'data-submission-portal-configuration',
  title: null,
  backUrl: null,
  endpoint: '/dsp/organization/${this.params.id}/settings',
  createEndpoint: null,
  fields: [
    {
      name: 'id',
      interface: 'hidden',
      type: 'integer',
      label: '',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'title',
      interface: 'label',
      type: 'string',
      label: '<h2>Data Submission Portal Settings</h2>',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'submission-settings',
      interface: 'label',
      type: 'string',
      label: '<h3>Submission Settings</h3>',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'bulk_approve_submit_properties',
      interface: 'checkbox',
      type: 'boolean',
      attr: {
        style: {
          width: 'unset',
        },
      },
      templateString:
        '<b>Bulk Approve/Submit Properties</b> - Ability to multi select property submissions as a reviewer and bulk approve or submit them at once. Reviewers are also not required to review property submission package reports individually prior to approving or submitting. DSP admins can configure this submission setting for each service provider once this is enabled.',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'provider_submission_testing_mode',
      interface: 'checkbox',
      type: 'boolean',
      attr: {
        style: {
          width: 'unset',
        },
      },
      templateString:
        '<b>Provider Submission Testing Mode</b> - Ability to enter a submission test mode to explore and validate Data Submission Portal features before using them in the Production mode. In this mode, you can test creating submission batches, prepare submissions, review submissions, and track the end-to-end test workflow without affecting production data or workflows. You can easily switch to the test mode to simulate real usage and ensure everything works as expected - then switch back when you’re ready to go live.',
      hint: '',
      required: false,
      validation: null,
    },
  ],
};
