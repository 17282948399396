export const user = {
  formName: 'user',
  title: '${this.params.operation} User',
  backUrl: '/admin/organizations/${this.params.organization_id}/users',
  endpoint:
    '/organizations/${this.params.organization_id}/users/${this.params.id}',
  createEndpoint: '/organizations/${this.params.organization_id}/users/',
  fields: [
    {
      name: 'intro',
      interface: 'label',
      type: 'string',
      label: 'Fields marked with asterick (*) are mandatory.',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'organizationId',
      interface: 'hidden',
      type: 'integer',
      label: '',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'firstName',
      interface: 'textbox',
      type: 'string',
      label: 'First Name',
      hint: '',
      required: true,
      validation: null,
    },
    {
      name: 'lastName',
      interface: 'textbox',
      type: 'string',
      label: 'Last Name',
      hint: '',
      required: true,
      validation: null,
    },
    {
      name: 'email',
      interface: 'textbox',
      type: 'string',
      label: 'Email Address',
      hint: '',
      required: true,
      disabled: true,
      validation: ['email'],

      dynamicProps: {
        disabled: ({ operation }) => operation === 'Edit',
      },
    },
    {
      name: 'role',
      interface: 'select',
      type: 'string',
      label: 'Core Explore Role',
      hint: '',
      required: true,
      validation: null,
      optionsEndpoint: '/groups',
      options: [
        { name: 'Admin', value: 'admin' },
        { name: 'Regular', value: 'regular' },
        { name: 'DSP Admin', value: 'dsp_admin' },
        { name: 'DSP User', value: 'dsp_regular' },
      ],
      value: 'regular',
    },
    {
      name: 'skipWelcomeEmail',
      type: 'boolean',
      label: 'Skip Welcome Email',
      hint: '',
      required: false,
      validation: null,
      dynamicProps: {
        interface: ({ operation }) =>
          operation === 'Edit' ? null : 'checkbox',
      },
    },
  ],
};
