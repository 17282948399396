import {
  getOrganizationLookerDashboards,
  getPowerBiReports,
} from 'src/products/admin/redux/dashboards/actions.js';
import { getPermissions } from '../../permissions/getActions';
import { Permission } from 'src/products/shell/redux/reducers/user';
import { PowerBIReport } from 'src/products/core-bi-v2/types';

const DASHBOARD_LANDING_PAGE_SOURCE_OPTIONS = {
  LOOKER: {
    name: 'Looker',
    value: 'looker',
  },
  POWER_BI: {
    name: 'Power BI',
    value: 'powerbi',
  },
};

export const coreProspectConfiguration = {
  formName: 'core-prospect-configuration',
  title: null,
  backUrl: null,
  endpoint: '/organizations/${this.params.id}/attributes',
  createEndpoint: null,
  fields: [
    {
      name: 'id',
      interface: 'hidden',
      type: 'integer',
      label: '',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'Title',
      interface: 'label',
      type: 'string',
      label: '<h2>CoreExplore Settings</h2>',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'csv-settings',
      interface: 'label',
      type: 'string',
      label: '<h3>CSV Settings</h3>',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'file_export_limit',
      interface: 'textbox',
      type: 'integer',
      label: 'Daily Export Limit (Per User)',
      hint: '',
      required: true,
      validation: null,
    },
    {
      name: 'export_limit',
      interface: 'textbox',
      type: 'integer',
      label: 'Row Limit Per User Download',
      hint: '',
      required: true,
      validation: null,
    },
    {
      name: 'deal-integration',
      interface: 'label',
      type: 'string',
      label: '<h3>Deals Integration</h3>',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'deals_enabled',
      interface: 'checkbox',
      type: 'boolean',
      label: 'Deals integration enabled',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'deals_api_client_name',
      interface: 'select',
      type: 'string',
      label: 'Deals API Client Name',
      options: () => {
        return [
          {
            name: 'Fake Deals Client',
            value: 'fake_deals_client',
          },
          {
            name: 'Demo Deals Client',
            value: 'demo_deals_client',
          },
          {
            name: 'EQT Deals Client',
            value: 'eqt_deals_client',
          },
        ];
      },
      required: null,
      validation: null,
    },
    {
      name: 'dashboard-property-link-title',
      interface: 'label',
      type: 'string',
      label: '<h3>Link Properties to a Dashboard</h3>',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'dashboard-property-link-subtitle',
      interface: 'label',
      type: 'string',
      label:
        '<h5>Select a dashboard that will be accessible from the property click card and detail page.</h5>',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'dashboard_property_link',
      interface: 'select',
      type: 'string',
      label: 'Select Dashboard',
      options: async (state, dispatch) => {
        const orgId = state.router.location.state.params.id;

        const orgPermissions = await dispatch(
          getPermissions('organizationPermissions', { organization_id: orgId })
        );

        const powerBiPermission = orgPermissions.find(
          ({ id }: { id: Permission }) => {
            return id === 'PowerBI';
          }
        );

        if (!powerBiPermission?.enabled) {
          return [];
        }

        try {
          const powerbiReports: { reports: PowerBIReport[] } = await dispatch(
            getPowerBiReports(orgId)
          );

          return [
            { name: 'None', value: null },
            ...powerbiReports.reports
              .map((report) => {
                const env = report.environment;
                const displayEnv = env ? `(${env.label})` : '';

                return {
                  name: `${report.name} ${displayEnv}`,
                  value: report.id,
                };
              })
              .sort((a, b) => a.name.localeCompare(b.name)),
          ];
        } catch (e) {
          return [
            {
              name: 'Could not load dashboards',
              value: null,
              disabled: true,
            },
          ];
        }
      },
      required: null,
      validation: null,
    },
    {
      name: 'dashboard-landing-page-title',
      interface: 'label',
      type: 'string',
      label: '<h3>Display Dashboard as Landing Page</h3>',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'dashboard-landing-page-subtitle',
      interface: 'label',
      type: 'string',
      label:
        '<h5>Select a dashboard that will display as landing page after login.</h5>',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'dashboard_landing_page_source',
      interface: 'select',
      type: 'string',
      label: 'Select Dashboard Source',
      options: async (state, dispatch) => {
        const orgId = state.router.location.state.params.id;

        const options = [DASHBOARD_LANDING_PAGE_SOURCE_OPTIONS.LOOKER];

        const orgPermissions = await dispatch(
          getPermissions('organizationPermissions', { organization_id: orgId })
        );

        const powerBiPermission = orgPermissions.find(
          ({ id }: { id: Permission }) => {
            return id === 'PowerBI';
          }
        );

        if (powerBiPermission?.enabled) {
          options.push(DASHBOARD_LANDING_PAGE_SOURCE_OPTIONS.POWER_BI);
        }

        return options;
      },
      required: null,
      validation: null,
    },
    {
      name: 'dashboard_landing_page',
      interface: 'select',
      type: 'string',
      label: 'Select Dashboard',
      condition: {
        when: 'dashboard_landing_page_source',
        equalTo: DASHBOARD_LANDING_PAGE_SOURCE_OPTIONS.POWER_BI.value,
      },
      options: async (state, dispatch) => {
        const orgId = state.router.location.state.params.id;

        try {
          const powerbiReports: { reports: PowerBIReport[] } = await dispatch(
            getPowerBiReports(orgId)
          );

          return [
            { name: 'None', value: null },
            ...powerbiReports.reports
              .filter((report) => report.environment?.name !== 'Development')
              .map((report) => {
                const env = report.environment;
                const displayEnv = env ? `(${env.label})` : '';

                return {
                  name: `${report.name} ${displayEnv}`,
                  value: report.id,
                };
              })
              .sort((a, b) => a.name.localeCompare(b.name)),
          ];
        } catch (e) {
          return [
            {
              name: 'Could not load dashboards',
              value: null,
              disabled: true,
            },
          ];
        }
      },
      required: null,
      validation: null,
    },
    {
      name: 'dashboard_landing_page',
      interface: 'select',
      type: 'string',
      label: 'Select Dashboard',
      condition: {
        when: 'dashboard_landing_page_source',
        equalTo: DASHBOARD_LANDING_PAGE_SOURCE_OPTIONS.LOOKER.value,
      },
      options: async (state, dispatch) => {
        const orgId = state.router.location.state.params.id;

        try {
          const lookerDashboards = await dispatch(
            getOrganizationLookerDashboards(orgId)
          );

          return [
            { name: 'None', value: null },

            ...lookerDashboards
              .map((dashboard) => ({
                name: dashboard.name,
                value: dashboard.slug,
              }))
              .sort((a, b) => a.name.localeCompare(b.name)),
          ];
        } catch (e) {
          return [
            {
              name: 'Could not load dashboards',
              value: null,
              disabled: true,
            },
          ];
        }
      },
      required: null,
      validation: null,
    },
    {
      name: 'map-settings',
      interface: 'label',
      type: 'string',
      label: '<h3>Map settings</h3>',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'default_map_to_satellite_view',
      interface: 'checkbox',
      type: 'boolean',
      label: 'Default Map to Satellite View',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'access-to-partners-data-settings',
      interface: 'label',
      type: 'string',
      label: '<h3>Access to partners data</h3>',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'safegraph_enabled',
      interface: 'checkbox',
      type: 'boolean',
      label: 'Safegraph Enabled',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'compstak_enabled',
      interface: 'checkbox',
      type: 'boolean',
      label: 'Compstak Enabled',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'rca_enabled',
      interface: 'checkbox',
      type: 'boolean',
      label: 'RCA Enabled',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'trepp_enabled',
      interface: 'checkbox',
      type: 'boolean',
      label: 'Trepp Enabled',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'reis_enabled',
      interface: 'checkbox',
      type: 'boolean',
      label: 'Reis Enabled',
      hint: '',
      required: false,
      validation: null,
    },
  ],
};
