export const customerUser = {
  formName: 'customerUser',
  title: '${this.params.operation} User',
  backUrl: '/customerAdmin/users',
  endpoint:
    '/organizations/${this.params.organization_id}/users/${this.params.id}',
  createEndpoint: '/organizations/${this.params.organization_id}/users/',
  fields: [
    {
      name: 'intro',
      interface: 'label',
      type: 'string',
      label: 'Fields marked with asterick (*) are mandatory.',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'organizationId',
      interface: 'hidden',
      type: 'integer',
      label: '',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'firstName',
      interface: 'textbox',
      type: 'string',
      label: 'First Name',
      hint: '',
      required: true,
      validation: null,
    },
    {
      name: 'lastName',
      interface: 'textbox',
      type: 'string',
      label: 'Last Name',
      hint: '',
      required: true,
      validation: null,
    },
    {
      name: 'email',
      interface: 'textbox',
      type: 'string',
      label: 'Email Address',
      hint: '',
      required: true,
      validation: ['email'],
    },
    {
      name: 'role',
      interface: 'select',
      type: 'string',
      label: 'Role',
      hint: '',
      required: true,
      validation: null,

      options: [
        { name: 'admin', value: 'admin' },
        { name: 'regular', value: 'regular' },
      ],
      value: 'regular',
    },
    {
      name: 'skipWelcomeEmail',
      type: 'boolean',
      label: 'Skip Welcome Email',
      hint: '',
      required: false,
      validation: null,
      dynamicProps: {
        interface: ({ operation }) =>
          operation === 'Edit' ? null : 'checkbox',
      },
    },
  ],
};
