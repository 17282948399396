import React from 'react';
import { Button } from '@cherre-frontend/ui';
import { Container, Title, Text, FieldsContainer } from './Snowflake';
import * as C from './styles';

const SnowflakeSkeleton: React.FC = () => {
  return (
    <Container>
      <Title>2. Implementation Details</Title>
      <Text>
        Configuring Snowflake involves setting up the necessary connections,
        permissions and integration parameters to allow data to flow seamlessly
        into your Snowflake environment. Read the our full guide on configuring
        Snowflake as a destination here. Before starting the implementation of a
        Cherre Egress for Snowflake, there is some information we need:
      </Text>
      <br />
      <FieldsContainer>
        <C.FieldBox>
          <C.FieldTitle>Account ID:</C.FieldTitle>
          <C.FieldInput size='small' value={''} disabled />
        </C.FieldBox>
        <C.FieldBox>
          <C.FieldTitle>Region:</C.FieldTitle>
          <C.FieldSelect value={''} disabled />
        </C.FieldBox>
      </FieldsContainer>
      <C.Actions>
        <Button disabled>Cancel</Button>
        <Button disabled variant='contained'>
          Next
        </Button>
      </C.Actions>
    </Container>
  );
};

export default SnowflakeSkeleton;
