import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Page from 'src/components/ItemEditFormPage';

import getActions from '../redux/forms/getActions';
import fillTemplate from 'src/utils/fillTemplate';

import {
  showErrorPopupNotification,
  showSuccessPopupNotification,
} from 'src/products/shell/Notifications';
import snakeCase from '../../../utils/snakeCase';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router';

export function createSuccessAction(formName) {
  const message = `Successfully created new ${formName}.`;
  showSuccessPopupNotification(message);
}

export function editSuccessAction(formName) {
  const message = `Successfully updated ${formName}.`;
  showSuccessPopupNotification(message);
}

export function invalidConnectorAssignmentAction() {
  const message =
    'Cannot assign Connectors access. No DOM owner found for organization.';
  showErrorPopupNotification(message);
}

export default function (schema) {
  const { formName, title, description, backUrl, fields, submitText } = schema;

  const { getItem, updateItem, createItem, resetItem } = getActions(schema);

  class FormComponent extends React.Component {
    componentDidMount() {
      const params = this.props.params || this.props.match.params;
      if (params && (params.id || schema.noId)) {
        this.props.getItem(params);
      }
    }

    componentWillUnmount() {
      this.props.resetItem(formName);
    }

    render() {
      const { store } = this.props;
      const params = this.props.params || this.props.match.params;

      const navigateBack = (backUrl) => {
        this.props.push(backUrl);
      };

      const operation = params.id || schema.noId ? 'Edit' : 'Create';
      const submitAction =
        operation === 'Edit' ? this.props.updateItem : this.props.createItem;
      const submitButtonText = submitText || (params.id ? 'Update' : 'Add');
      const resolvedTitle =
        title && fillTemplate(title, { params: { operation } });
      const resolvedBackUrl =
        backUrl && fillTemplate(backUrl, { params: params });
      const resolvedDescription =
        description && fillTemplate(description, { params: params });

      const afterSubmit = () => {
        if (operation === 'Edit') {
          editSuccessAction(formName);
        } else {
          createSuccessAction(formName);
        }

        if (resolvedBackUrl) {
          this.props.push(resolvedBackUrl);
        }
      };

      if (schema.usesSnakeCase) {
        store.data = snakeCase(store.data);
      }

      return (
        <Page
          operation={operation}
          formName={formName}
          submit={(fieldValues) => {
            return submitAction({ ...params }, fieldValues)
              .then(afterSubmit)
              .catch(() => {}); // This catch recognizes the error thrown in the updateItem action and prevents the after submit from running on a failure
          }}
          backButtonClick={
            !resolvedBackUrl
              ? null
              : () => {
                  navigateBack(resolvedBackUrl);
                }
          }
          initialValues={store?.data}
          backUrl={resolvedBackUrl}
          pageTitle={resolvedTitle}
          pageDescription={resolvedDescription}
          fields={fields}
          store={store}
          submitButtonText={submitButtonText}
        />
      );
    }
  }

  FormComponent.propTypes = {
    resetItem: PropTypes.func,
    store: PropTypes.object,
    initialValues: PropTypes.object,
    getItem: PropTypes.func,
    updateItem: PropTypes.func,
    createItem: PropTypes.func,
  };

  function mapStateToProps(state) {
    const {
      adminPanel: { [formName]: store },
    } = state;
    return { store };
  }

  return connect(mapStateToProps, {
    getItem,
    updateItem,
    createItem,
    resetItem,
    push,
  })(withRouter(FormComponent));
}
