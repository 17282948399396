import { constSelector } from 'recoil';
import { getSelector, useCherreValue } from '@cherre-frontend/data-fetching';
import type { UserFormValues } from 'src/products/admin/pages/User/Form/types';
import type { User } from 'src/products/admin/types/user';

export const getUserSelector = (organizationId: string, userId: string) => {
  return getSelector<User>({
    url: `/api/v1/organizations/${organizationId}/users/${userId}`,
  });
};

export const useEditedUserFormValues = (
  organizationId: string,
  userId: string | undefined
): UserFormValues | undefined => {
  const user = useCherreValue(
    userId ? getUserSelector(organizationId, userId) : constSelector(undefined)
  );

  if (!user) {
    return undefined;
  }

  return {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    role: user.role,
  };
};
