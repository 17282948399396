import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import getFormComponent from 'src/products/admin/pages/getFormComponent';
import { getPermissions } from 'src/products/admin/redux/permissions/getActions';
import { getPermissionFields } from 'src/products/admin/redux/permissions/createPermissionFields';

import schemas from 'src/products/admin/redux/permissions/schemas';

class Permissions extends React.Component {
  //const Component = getPermissionsComponent();
  formData = Object.assign({ fields: [] }, schemas.groupPermissions);
  updatedparams = {
    id: this.props.match.params.user_id,
    ...this.props.match.params,
  };
  componentDidMount() {
    this.props.getPermissions(this.formData.id, this.updatedparams);
  }

  render() {
    const props = this.props;
    if (!props.match.params.group_id) {
      return null;
    }
    const fields = getPermissionFields(this.props.availablePermissions);
    this.formData.fields = fields;

    const FormComponent = getFormComponent(this.formData);

    return (
      <FormComponent
        params={{
          formData: this.formData,
          ...props.match.params,
          id: true, //forces it into edit mode
        }}
      />
    );
  }
}

Permissions.propTypes = {
  params: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    availablePermissions: state.adminPanel.groupPermissions.permissionListData,
  };
}

export default connect(mapStateToProps, {
  getPermissions,
  getPermissionFields,
})(Permissions);
