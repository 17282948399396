import React from 'react';
import {
  PageHeader,
  PageContent,
  PageContainer,
  Tabs,
} from '@cherre-frontend/ui';
import * as Panels from './Panels';
import { RelayEnvironmentProvider } from 'react-relay';
import { createDomEnvironment } from 'src/products/shell/DomRelayEnvironment';
import { useParams } from 'react-router';

enum TAB_VALUES {
  GENERAL = 'general',
  PLAN = 'plan',
  API = 'api',
  DASHBOARD = 'dashboards',
  SEARCH = 'search',
  DESTINATIONS = 'destinations',
}

/**
 * OrganizationSettings component renders the settings page for an organization.
 * It includes a header, a container for the page content, and a set of tabs.
 *
 * @component
 * @example
 * return (
 *   <OrganizationSettings />
 * )
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @remarks
 * The component uses React's useState hook to manage the state of the currently selected tab.
 * The tabs include 'General', 'Plan', 'API', 'Dashboards', 'Search', and 'Destinations'.
 * Currently, only the 'Destinations' tab is enabled and displays its corresponding panel.
 *
 * @see {@link PageContainer}
 * @see {@link PageHeader}
 * @see {@link PageContent}
 * @see {@link Tabs}
 * @see {@link Panels.Destinations}
 */
const OrganizationSettings = () => {
  const [tab, setTab] = React.useState<TAB_VALUES>(TAB_VALUES.DESTINATIONS);
  return (
    <PageContainer id='organization-settings'>
      <PageHeader title='Settings' backLink='/admin/organizations' />
      <PageContent>
        <Tabs
          showBorder={false}
          tabs={[
            {
              label: 'General',
              value: TAB_VALUES.GENERAL,
              disabled: true,
            },
            {
              label: 'Plan',
              value: TAB_VALUES.PLAN,
              disabled: true,
            },
            {
              label: 'API',
              value: TAB_VALUES.API,
              disabled: true,
            },
            {
              label: 'Dashboards',
              value: TAB_VALUES.DASHBOARD,
              disabled: true,
            },
            {
              label: 'Search',
              value: TAB_VALUES.SEARCH,
              disabled: true,
            },
            {
              label: 'Destinations',
              value: TAB_VALUES.DESTINATIONS,
            },
          ]}
          value={tab}
          onChange={(_, newValue) => setTab(newValue)}
          tabPanels={[
            {
              value: TAB_VALUES.DESTINATIONS,
              panel: <Panels.Destinations />,
            },
          ]}
        />
      </PageContent>
    </PageContainer>
  );
};

export default () => {
  const { organization_id } = useParams<{ organization_id: string }>();
  return (
    <RelayEnvironmentProvider
      environment={createDomEnvironment(organization_id)}
    >
      <OrganizationSettings />
    </RelayEnvironmentProvider>
  );
};
