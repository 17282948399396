import { isAddButtonDisabledForNonAdmin } from 'src/products/admin/redux/tables/schemas/isAddButtonDisabled';

export const groups = {
  id: 'groups',
  nameKey: 'name',
  entityName: 'group',
  addItemUrl: '/admin/groups/new',
  isAddButtonDisabled: isAddButtonDisabledForNonAdmin,
  endpoint: '/groups',
  actions: ['edit', 'permissions'],
  columns: [
    {
      field_name: 'name',
      column_label: 'First Name',
      mask: '',
      sortable: true,
      col_type: 'normal',
      additional_props: null,
    },
  ],
};
