export default {
  organizationPermissions: {
    title: 'Edit Organization Permissions',
    formName: 'organizationPermissions',
    id: 'organizationPermissions',
    endpoint: '/organizations/${this.params.organization_id}/permissions',
    backUrl: '/admin/organizations',
    entityName: 'Edit Organization Permissions',
  },
  organizationDashboards: {
    title: 'Edit Organization Dashboards',
    formName: 'organizationDashboards',
    id: 'organizationDashboards',
    endpoint: '/organizations/${this.params.organization_id}/dashboards',
    backUrl: '/admin/organizations',
    entityName: 'Edit Organization Dashboards',
  },
  groupPermissions: {
    title: 'Edit Group Permissions',
    formName: 'groupPermissions',
    id: 'groupPermissions',
    endpoint: '/groups/${this.params.group_id}/permissions',
    backUrl: '/admin/groups',
    entityName: 'Edit Group Permissions',
  },
  userPermissions: {
    title: 'Edit User Permissions',
    formName: 'userPermissions',
    id: 'userPermissions',
    endpoint:
      '/organizations/${this.params.organization_id}/users/${this.params.user_id}/permissions',
    backUrl: '/admin/organizations/${this.params.organization_id}/users',
    entityName: 'Edit User Permissions',
  },
  customerUserPermissions: {
    title: 'Edit User Permissions',
    formName: 'userPermissions',
    id: 'userPermissions',
    endpoint:
      '/organizations/${this.params.organization_id}/users/${this.params.user_id}/permissions',
    backUrl: '/customerAdmin/users',
    entityName: 'Edit User Permissions',
  },
};
