import React from 'react';
import getFormComponent from '../../getFormComponent';
import schemas from 'src/products/admin/redux/forms/schemas';
import { fieldsSelector } from './recoil';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router';

const Dashboard = (props) => {
  const params = useParams<any>();
  const fields = useRecoilValue(fieldsSelector(params.organization_id));

  const Component = React.useMemo(
    () =>
      getFormComponent({
        ...schemas['dataset-dashboards'],
        fields,
      }),
    [fields]
  );

  return <Component {...props} />;
};

Dashboard.displayName = 'DatasetDashboardsAdmin';

export default Dashboard;
