import React from 'react';
import cx from 'classnames';
import getFormComponent from 'src/products/admin/pages/getFormComponent';
import { connect, useDispatch } from 'react-redux';
import schemas from 'src/products/admin/redux/forms/schemas';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import withStyles from '@material-ui/core/styles/withStyles';
import { push } from 'connected-react-router';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  body: {
    width: '100%',
    maxWidth: 980,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 25,
  },
  paper: {
    width: 220,
    marginRight: 20,
  },
  listItem: {
    cursor: 'pointer',
  },
  activeListItem: {
    background: '#eee',
  },
};

const icons = {
  'core-bi-configuration': 'Settings-2',
  'power-bi-configuration': 'Settings-2',
  'core-prospect-configuration': 'Settings-2',
  'core-schema-configuration': 'Settings-2',
};

const listItems = [
  {
    schemaName: 'general-configuration',
    text: 'General Settings',
    getUrl: (params) =>
      `/admin/organizations/${params.id}/configuration/general-configuration`,
    getParams: (params) => ({
      id: params.id,
    }),
  },
  {
    schemaName: 'core-bi-configuration',
    text: 'Core BI',
    getUrl: (params) =>
      `/admin/organizations/${params.id}/configuration/core-bi-configuration`,
    getParams: (params) => ({
      id: params.id,
    }),
  },
  {
    schemaName: 'power-bi-configuration',
    text: 'Power BI',
    getUrl: (params) =>
      `/admin/organizations/${params.id}/configuration/power-bi-configuration`,
    getParams: (params) => ({
      id: params.id,
    }),
  },
  {
    schemaName: 'core-prospect-configuration',
    text: 'Core Explore',
    getUrl: (params) =>
      `/admin/organizations/${params.id}/configuration/core-prospect-configuration`,
    getParams: (params) => ({
      id: params.id,
    }),
  },
  {
    schemaName: 'core-schema-configuration',
    text: 'Core Schema',
    getUrl: (params) =>
      `/admin/organizations/${params.id}/configuration/core-schema-configuration`,
    getParams: (params) => ({
      id: params.id,
    }),
  },
  {
    schemaName: 'data-submission-portal-configuration',
    text: 'Data Submission Portal',
    getUrl: (params) =>
      `/admin/organizations/${params.id}/configuration/data-submission-portal-configuration`,
    getParams: (params) => ({
      id: params.id,
    }),
  },
];

const Configuration = ({ match: { params }, classes }) => {
  const schemaName = params.schemaName || 'core-bi-configuration';

  const FormComponent = getFormComponent(schemas[schemaName]);

  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <h2 style={{ color: '#000' }}>Organization Settings</h2>

        <div className={classes.wrapper}>
          <Paper className={classes.paper}>
            <List>
              {listItems.map((item) => (
                <ListItem
                  key={item.schemaName}
                  onClick={() =>
                    dispatch(
                      push(item.getUrl(params), {
                        params: item.getParams(params),
                      })
                    )
                  }
                  className={cx(classes.listItem, {
                    [classes.activeListItem]: item.schemaName === schemaName,
                  })}
                >
                  <i
                    style={{ marginRight: 15 }}
                    className={`icon icon-${
                      icons[item.schemaName] ?? 'Settings-2'
                    }`}
                  />{' '}
                  {item.text}
                </ListItem>
              ))}
            </List>
          </Paper>
          <FormComponent
            params={{
              ...params,
              id: params.id,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    organization_id: state.user.profile.value.organizationId,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Configuration));
