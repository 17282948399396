import React from 'react';
import getFormComponent from '../../getFormComponent';
import schemas from 'src/products/admin/redux/forms/schemas';
import useFields from './useFields';
import { useParams } from 'react-router';

const Dashboard = (props) => {
  const params = useParams();
  const { fields, isLoading } = useFields(params.organization_id);

  const Component = getFormComponent({
    ...schemas.dashboards,
    fields,
  });

  if (isLoading) {
    return <span>Loading...</span>;
  }

  return <Component {...props} />;
};

export default Dashboard;
