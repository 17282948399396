import { PRODUCTS } from 'src/const';

export const coreSchemaConfiguration = {
  formName: 'core-schema-configuration',
  title: null,
  backUrl: null,
  endpoint: '/organizations/${this.params.id}/attributes',
  createEndpoint: null,
  fields: [
    {
      name: 'id',
      interface: 'hidden',
      type: 'integer',
      label: '',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'Title',
      interface: 'label',
      type: 'string',
      label: `<h2>${PRODUCTS.SCHEMA} Organization Settings</h2>`,
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'graphql_endpoint',
      interface: 'textbox',
      type: 'integer',
      label: 'GraphQL endpoint',
      hint: '',
      required: false,
      validation: null,
    },
  ],
};
