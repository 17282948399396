export const datatable = {
  formName: 'datatable',
  title: '${this.params.operation} Datatable',
  backUrl:
    '/admin/organizations/${this.params.organization_id}/datasets/${this.params.dataset_id}/datatables',
  endpoint:
    '/organizations/datasets/${this.params.dataset_id}/datatables/${this.params.id}',
  createEndpoint:
    '/organizations/${this.params.organization_id}/datasets/${this.params.dataset_id}/datatables',
  fields: [
    {
      name: 'name',
      interface: 'textbox',
      type: 'string',
      label: 'Datatable Name',
      hint: '',
      required: true,
      validation: null,
    },
    {
      name: 'description',
      interface: 'textbox',
      type: 'string',
      label: 'Datatable Description',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'isDisabled',
      interface: 'checkbox',
      type: 'boolean',
      label: 'Disabled',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'dataDictionary',
      interface: 'csv',
      label: 'Upload Data Dictionary',
      endpoint: '/api/v1/organizations/datasets/parse-csv?type=dataDictionary',
    },
    {
      name: 'dataPreview',
      interface: 'csv',
      label: 'Upload Data Preview',
      endpoint: '/api/v1/organizations/datasets/parse-csv?type=dataPreview',
    },
  ],
};
