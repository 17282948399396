import React, { useEffect, useMemo, useState } from 'react';
import { PaperPage } from 'src/components/PaperPage';
import { getBackUrl } from '../../../utils/getBackUrl';
import { useHistory, useParams } from 'react-router';
import { useCherreValue, useIsSuspended } from '@cherre-frontend/data-fetching';
import { Button, PageContainer, Typography } from '@cherre-frontend/ui';
import {
  productAccessSelector,
  useUpdatePermissions,
  PermissionStatus,
} from './recoil';
import { useSelector } from 'react-redux';
import { DefaultErrorBoundary } from 'src/products/shell/DataFetchingConfig';
import permissions from 'src/const/permissions';
import { ProductLines, FormGroupStyled, FormLabelStyled } from './styles';
import { uniq, isEqual } from 'lodash';
import { PRODUCT_LINES } from 'src/const';
import { PermissionSelection } from './PermissionSelection';
import { InputLabel } from 'src/products/admin/components/InputLabel';

type FormState = {
  [permission: string]: boolean;
};

const getPermissionsByProductLine = (userPermissions: PermissionStatus[]) => {
  const userPermissionNames = userPermissions.map((p) => p.name);
  const filteredPermissions = permissions.filter((p) => {
    if (p.children) {
      return p.children.every((child) =>
        userPermissionNames.includes(child.permission)
      );
    } else {
      return userPermissionNames.includes(p.permission);
    }
  });

  // Filter out development and administration sections
  const productLines = uniq(
    filteredPermissions.map((p) => p.productLine)
  ).filter((productLine) => {
    return (
      productLine !== PRODUCT_LINES.DEVELOPMENT &&
      productLine !== PRODUCT_LINES.ADMINISTRATION
    );
  });

  return productLines
    .sort((a, b) => {
      const order = [
        PRODUCT_LINES.DEVELOPMENT,
        PRODUCT_LINES.CORE_EXPLORE,
        PRODUCT_LINES.CORE_CONNECT,
        PRODUCT_LINES.DATA_SUBMISSION_PORTAL,
        PRODUCT_LINES.ADMINISTRATION,
      ];
      const aIndex = order.indexOf(a);
      const bIndex = order.indexOf(b);

      if (aIndex === -1 && bIndex === -1) {
        return a.localeCompare(b);
      }

      if (aIndex === -1) {
        return 1;
      }

      if (bIndex === -1) {
        return -1;
      }

      return aIndex - bIndex;
    })
    .map((productLine) => ({
      productLine,
      permissions: filteredPermissions.filter(
        (p) => p.productLine === productLine
      ),
    }));
};

const SkeletonSuspense = () => {
  return (
    <ProductLines>
      {[...Object.values(PRODUCT_LINES)].map((productLine) => (
        <FormGroupStyled key={productLine}>
          <FormLabelStyled className='suspend'>{productLine}</FormLabelStyled>
          {[...Array(3)].map((_, i) => (
            <InputLabel key={i} label='Permission' description='Description' />
          ))}
        </FormGroupStyled>
      ))}
    </ProductLines>
  );
};

const ProductAccessPaperPage: React.FC = ({ children }) => {
  const params = useParams<{ user_id: string; organization_id?: string }>();
  const history = useHistory();

  return (
    <PaperPage
      backButtonClick={() => {
        const url = getBackUrl(
          '/customerAdmin/users',
          `/admin/organizations/${params.organization_id}/users`
        );
        history.push(url);
      }}
      pageTitle='Product Access'
    >
      {children}
    </PaperPage>
  );
};

function ProductAccess() {
  const params = useParams<{ user_id: string; organization_id?: string }>();

  const userOrganizationId = useSelector(
    (state) => state.user.profile.value.organizationId
  );

  const organizationId = params.organization_id
    ? parseInt(params.organization_id)
    : userOrganizationId;

  const suspended = useIsSuspended();

  if (!organizationId) {
    throw new Error('Organization ID not found');
  }

  const userId = parseInt(params.user_id);

  const currentProductAccess = useCherreValue(
    productAccessSelector(organizationId, userId)
  );

  const [formState, setFormState] = useState<FormState>();
  const [initialFormState, setInitialFormState] = useState<FormState>();

  useEffect(() => {
    if (currentProductAccess) {
      const formState = currentProductAccess.reduce((acc, permission) => {
        acc[permission.name] = permission.enabled;
        return acc;
      }, {} as FormState);

      setFormState(formState);
      setInitialFormState(formState);
    }
  }, [currentProductAccess]);

  const update = useUpdatePermissions(organizationId, userId, (context) => {
    context.showSnackbar({
      type: 'success',
      message: 'Product access updated',
    });
  });

  const permissionsByProductLine = useMemo(
    () => getPermissionsByProductLine(currentProductAccess ?? []),
    [currentProductAccess]
  );

  const notChanged = isEqual(formState, initialFormState);

  return (
    <ProductAccessPaperPage>
      {suspended && !formState && <SkeletonSuspense />}
      {formState && (
        <ProductLines>
          {permissionsByProductLine.map(({ permissions, productLine }) => (
            <FormGroupStyled key={productLine}>
              <FormLabelStyled className='suspend'>
                {productLine}
              </FormLabelStyled>
              {permissions.map((p) => (
                <PermissionSelection
                  key={p.permission}
                  permission={p}
                  initialFormState={initialFormState}
                  formState={formState}
                  setFormState={setFormState}
                />
              ))}
            </FormGroupStyled>
          ))}
        </ProductLines>
      )}
      {!suspended && !permissionsByProductLine.length && (
        <Typography>No products found</Typography>
      )}
      <Button
        variant='contained'
        type='submit'
        style={{ marginTop: 10 }}
        className='suspend'
        onClick={() => {
          if (!formState) {
            return;
          }

          update(formState);
        }}
        disabled={notChanged}
      >
        Update
      </Button>
    </ProductAccessPaperPage>
  );
}

export default () => (
  <PageContainer
    id='user-product-access-admin'
    errorFallback={(props) => {
      const error = props.error;

      if ((error as any)?.message === 'Organization ID not found') {
        return (
          <ProductAccessPaperPage>
            <Typography>Organization ID not found</Typography>
          </ProductAccessPaperPage>
        );
      }

      return <DefaultErrorBoundary {...props} />;
    }}
  >
    <ProductAccess />
  </PageContainer>
);
