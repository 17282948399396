export const connectorsConfiguration = {
  formName: 'connectors-configuration',
  title: null,
  backUrl: null,
  endpoint:
    '/organizations/${this.params.organization_id}/users/${this.params.id}/attributes',
  createEndpoint: null,
  fields: [
    {
      name: 'id',
      interface: 'hidden',
      type: 'integer',
      label: '',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'Title',
      interface: 'label',
      type: 'string',
      label: `<h2>Connectors User Settings</h2>`,
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'sendConnectorsNotification',
      interface: 'checkbox',
      type: 'boolean',
      label: 'Send Connectors Notification',
      hint: '',
      required: false,
      validation: null,
    },
  ],
};
