export const userFirstTimeLogin = {
  formName: 'user-first-time-login',
  title: null,
  backUrl: null,
  endpoint:
    '/organizations/${this.params.organization_id}/users/${this.params.id}/attributes',
  createEndpoint: null,
  fields: [
    {
      name: 'id',
      interface: 'hidden',
      type: 'integer',
      label: '',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'Title',
      interface: 'label',
      type: 'string',
      label: '<h2>User First Time Login</h2>',
      hint: '',
      required: false,
      validation: null,
    },
    {
      name: 'hasLoggedIn',
      interface: 'checkbox',
      type: 'boolean',
      label: 'First Time User Login',
      hint: '',
      required: false,
      validation: null,
    },
  ],
};
