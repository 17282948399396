import {
  CHERRE_ADMIN_PERMISSIONS,
  CHERRE_ADMIN_ALLOWED_ORGS,
} from 'src/products/core-prospect/constants';
import { Permission } from 'src/products/shell/redux/reducers/user';

export const filterCherreAdminPermissions = (
  permissions: { id: Permission }[],
  organizationId: number,
  isCherreAdmin: boolean
) => {
  if (
    isCherreAdmin &&
    CHERRE_ADMIN_ALLOWED_ORGS.includes(Number(organizationId))
  ) {
    return permissions;
  }
  // get available permissions and filter out Cherre*Admin permissions if the organization is not in the allowed orgs
  return permissions.filter(
    (permission) => !CHERRE_ADMIN_PERMISSIONS.includes(permission.id)
  );
};
