export const migrations = {
  id: 'migrations',
  nameKey: 'migrationName',
  endpoint: '/migrations',
  entityName: 'Cherre Web Apps Database Migration',
  actions: [
    'deploy-migrations',
    'resolve-migration-as-applied',
    'resolve-migrations-as-rolled-back',
    'resolve-migration-as-reseted',
    'flush-sessions',
  ],
  columns: [
    {
      field_name: 'id',
      column_label: 'id',
      mask: '',
      col_type: 'normal',
    },
    {
      field_name: 'migrationName',
      column_label: 'Name',
      mask: '',
      col_type: 'normal',
    },
    {
      field_name: 'appliedStepsCount',
      column_label: 'Applied Steps Count',
      mask: '',
      col_type: 'normal',
    },
    {
      field_name: 'logs',
      column_label: 'Logs',
      mask: '',
      col_type: 'truncated',
    },
    {
      field_name: 'startedAt',
      column_label: 'Started At',
      mask: 'date',
      col_type: 'normal',
    },
    {
      field_name: 'finishedAt',
      column_label: 'Finished At',
      mask: 'date',
      col_type: 'normal',
    },
    {
      field_name: 'rolledBackAt',
      column_label: 'Rolled Back At',
      mask: 'date',
      col_type: 'normal',
    },
  ],
};
