import React from 'react';
import {
  styled,
  DataConnectionIcon,
  Typography,
  Button,
  AddCircleIcon,
} from '@cherre-frontend/ui';
import { useUIState } from './useUIState';

const Container = styled('div')`
  padding-top: 100px;
  padding-bottom: 100px;
  display: grid;
  grid-template-rows: auto 6px auto 6px auto 20px auto;
  grid-template-areas: 'icon' '.' 'title' '.' 'subtitle' '.' 'button';
  grid-template-columns: 1fr;
  width: 100%;
  justify-items: center;
`;

const Icon = styled(DataConnectionIcon)`
  grid-area: icon;
  font-size: 36px;
`;

const Title = styled(Typography)`
  grid-area: title;
  color: ${({ theme }) => theme.palette.text.primary};

  /* Heading 3 */
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.4px;
`;

const Subtitle = styled(Typography)`
  grid-area: subtitle;
  color: ${({ theme }) => theme.palette.text.primary};

  /* Body */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const AddButton = styled(Button)`
  grid-area: button;
`;

/**
 * NoDestinations component displays a message indicating that there are no destinations configured.
 * It provides a button to add a new destination.
 *
 * @component
 * @returns {JSX.Element} The rendered NoDestinations component.
 */
const NoDestinations: React.FC = () => {
  const [, setUIState] = useUIState();
  return (
    <Container>
      <Icon />
      <Title>No Destinations</Title>
      <Subtitle>
        There are no destinations configured yet, click the button below to
        configure your first destination!
      </Subtitle>
      <AddButton
        endIcon={<AddCircleIcon />}
        variant='contained'
        onClick={() => setUIState({ step: 'add-destination' })}
      >
        Add Destination
      </AddButton>
    </Container>
  );
};

export default NoDestinations;
