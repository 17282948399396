import { FetchStatus } from 'src/products/admin/types/fetch-status';
import schemas from './schemas';
import * as types from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- using any for now due to the generic nature of this reducer
interface TablesReducerState<D = any, C = any, F = any> {
  fetchStatus: Record<string, FetchStatus>;
  deleteStatus: Record<string, FetchStatus>;
  createStatus: Record<string, FetchStatus>;
  countStatus: Record<string, FetchStatus>;
  data: Record<string, D>[];
  columns: Record<string, C>[];
  filters: Record<string, F>;
  order: string;
  orderBy: string | null;
  searchValue: string;
  page: number;
  rowsPerPage: number;
  total: number;
}

const getInitialState = (
  columns,
  orderBy = null,
  order = 'asc',
  filters = []
): TablesReducerState => ({
  fetchStatus: {},
  deleteStatus: {},
  createStatus: {},
  countStatus: {},
  data: [],
  columns,
  filters,
  order,
  orderBy,
  searchValue: '',
  page: 0,
  rowsPerPage: 10,
  total: 0,
});

export const getTableReducer = (columns, schemaId, orderBy, order, filters) => {
  const initialState = getInitialState(columns, orderBy, order, filters);

  function reducer(state = initialState, action): TablesReducerState {
    if (!action.schemaId) {
      return state;
    }

    if (action.schemaId !== schemaId) {
      return state;
    }

    switch (action.type) {
      case types.DELETE_ITEM_REQUEST: {
        return {
          ...state,
          deleteStatus: {
            value: 'LOADING',
          },
        };
      }

      case types.DELETE_ITEM_SUCCESS: {
        return {
          ...state,
          deleteStatus: {
            value: 'LOADED',
          },
        };
      }

      case types.DELETE_ITEM_FAILED: {
        return {
          ...state,
          deleteStatus: {
            value: 'FAILED',
          },
        };
      }

      case types.GET_ITEMS_REQUEST: {
        return {
          ...state,
          fetchStatus: {
            value: 'LOADING',
          },
        };
      }

      case types.GET_ITEMS_SUCCESS: {
        return {
          ...state,
          data: action.data,
          fetchStatus: {
            value: 'LOADED',
          },
        };
      }

      case types.GET_ITEMS_REQUEST_COUNT: {
        return {
          ...state,
          countStatus: {
            value: 'LOADING',
          },
        };
      }

      case types.GET_ITEMS_SUCCESS_COUNT: {
        return {
          ...state,
          total: action.data,
          countStatus: {
            value: 'LOADED',
          },
        };
      }

      case types.CHANGE_ORDER: {
        return {
          ...state,
          order: action.order,
          orderBy: action.orderBy,
        };
      }

      case types.CHANGE_SEARCH_VALUE: {
        return {
          ...state,
          searchValue: action.searchValue,
        };
      }

      case types.CHANGE_PAGE: {
        return {
          ...state,
          page: action.page,
        };
      }

      case types.CHANGE_ROWS_PER_PAGE: {
        return {
          ...state,
          rowsPerPage: action.rowsPerPage,
        };
      }

      case types.SET_FILTER_VALUE: {
        return {
          ...state,
          filters: {
            ...state.filters,
            [action.field]: {
              ...state.filters[action.field],
              value: action.value,
            },
          },
        };
      }

      default:
        return state;
    }
  }

  return reducer;
};

function getTablesReducers() {
  return Object.keys(schemas).reduce((memo, schemaId) => {
    const schema = schemas[schemaId];

    memo[schemaId] = getTableReducer(
      schema.columns,
      schemaId,
      schema.orderBy,
      schema.order,
      schema.filters
    );

    return memo;
  }, {} as Record<keyof typeof schemas, ReturnType<typeof getTableReducer>>);
}

export default getTablesReducers;
